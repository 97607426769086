<!-- 智慧安全 > 校园安保 > 出入组管理 -->
<template>
    <div>
        <div
            v-if="!errorShow"
            class="common-form"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                :style="{'margin-top': '0'}"
            >
                <div
                    v-if="!showAddEdit"
                    class="content-main"
                    style="margin-top: 0px"
                >
                    <!-- 顶部筛选 -->
                    <div class="filter-wrap-all">
                        <div v-if="role == 'group'" class="filter-wrap">
                            <expand-filter
                                :closeWidth="340"
                                :formData="formData"
                                @clickBtn="clickBtn"
                                class="content-wrapper"
                                :marginBottom="'1px'"
                            >
                            </expand-filter>
                            <el-checkbox v-if="isAccessGroupOnlyOne" style="margin-left: 10px;margin-right: 26px; line-height: 32px;" v-model="checked" @change="changeOnlyOne">只看人数为1的组</el-checkbox>
                            <div
                                class="button-line"
                                v-has-permi="[
                                    'accessGroup:add',
                                    'accessGroup:batchOper',
                                    'accessGroup:export',
                                ]"
                            ></div>
                            <div
                                slot="pageOperation"
                                style="padding-bottom: 10px;display: flex;"
                            >
                                <el-button
                                    type="primary"
                                    v-has-permi="['accessGroup:add']"
                                    @click="handleAdd"
                                >
                                    添加
                                </el-button>
                                <el-dropdown trigger="click">
                                    <!-- 批量操作 -->
                                    <el-button
                                        type="info"
                                        v-hasPermi="['accessGroup:batchOper']"
                                    >
                                        批量操作
                                        <i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            @click.native="multiChangeStatus(1)"
                                        >
                                            <span
                                                type="text"
                                                class="hover"
                                                >启用
                                            </span>
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            @click.native="multiChangeStatus(2)"
                                        >
                                            <span
                                                type="text"
                                                class="hover"
                                                >禁用
                                            </span>
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="multiDel">
                                            <span
                                                type="text"
                                                class="hover"
                                                >删除
                                            </span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <div
                                    class="set_btn"
                                    v-if="isAccessGroupSet"
                                    @click="handleSet"
                                    style="margin-left: 10px;"
                                >
                                    设置
                                </div>
                            </div>
                            <div
                                slot="other-btn"
                                style="flex: 1; display: flex"
                                class="export_box"
                            >
                                <el-dropdown
                                    trigger="click"
                                    v-if="exportState"
                                    style="
                                        background-color: #ffffff;
                                        margin-left: auto;
                                    "
                                >
                                    <el-button
                                        type="enquiry"
                                        style="margin: 0 10px"
                                        :loading="exportBtnLoading"
                                    >
                                        导出<i
                                            class="el-icon-caret-bottom el-icon--right"
                                        ></i>
                                    </el-button>
                                    <el-dropdown-menu
                                        slot="dropdown"
                                        style="background-color: #ffffff;"
                                    >
                                        <el-dropdown-item
                                            style="text-align: center"
                                        >
                                            <el-button
                                                style="
                                                    display: inline-block;
                                                    margin-left: 0;
                                                "
                                                type="text"
                                                class="hover"
                                                plain
                                                size="mini"
                                                @click="exportList"
                                                :loading="exportListLoading"
                                                v-hasPermi="['accessGroup:export']"
                                            >
                                                导出列表
                                            </el-button>
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            style="text-align: center"
                                        >
                                            <el-button
                                                style="
                                                    display: inline-block;
                                                    margin-left: 0;
                                                "
                                                type="text"
                                                class="hover"
                                                plain
                                                size="mini"
                                                @click="exportAll"
                                                :loading="exportAllLoading"
                                                v-hasPermi="['accessGroup:exportAll']"
                                            >
                                                导出全部
                                            </el-button>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <el-button
                                    v-if="!exportState"
                                    type="enquiry"
                                    style="margin-left: auto;"
                                    class="hover"
                                    plain
                                    size="mini"
                                    @click="exportAll"
                                    :loading="exportAllLoading"
                                    v-hasPermi="['accessGroup:exportAll']"
                                >
                                    导出全部
                                </el-button>
                                <el-button
                                    v-if="!exportState"
                                    type="enquiry"
                                    style="margin-left: auto;"
                                    class="hover"
                                    plain
                                    size="mini"
                                    @click="exportList"
                                    :loading="exportListLoading"
                                    v-hasPermi="['accessGroup:export']"
                                >
                                    导出列表
                                </el-button>
                            </div>

                        </div>
                        <div v-if="role == 'student'" class="filter-wrap">
                            <expand-filter
                                :closeWidth="340"
                                :formData="peopleFormData"
                                @clickBtn="peopleClickBtn"
                                @changeSel="changeSel"
                                class="content-wrapper"
                                :marginBottom="'1px'"
                            >
                            </expand-filter>
                        </div>
                        <div class="teacher-student" v-if="isAccessGroupPeopleList">
                            <div
                                class="teacher"
                                :class="role == 'group' ? 'active-style' : ''"
                                @click="setRole('group')"
                                >
                                <img :src="role == 'group' ? stuY : stuN" alt="">
                            </div>
                            <div
                                class="student"
                                :class="role == 'student' ? 'active-style' : ''"
                                @click="setRole('student')"
                                >
                                <img :src="role == 'student' ? teaY : teaN" alt="">
                            </div>
                        </div>
                    </div>
                    <div v-if="role == 'group'">
                        <!-- 表格 -->
                        <table-data
                            id="table"
                            ref="table"
                            :key="tableKey"
                            v-loading="loadingTable"
                            :config="table_config"
                            :tableData="table_data"
                            @handleSelectionChange="handleSelectionChange"
                            @switchHandler="
                                (data) =>
                                    changeStatus([
                                        {
                                            id: data.id,
                                            status: data.status === '1' ? '1' : '2',
                                        },
                                    ])
                            "
                        >
                            <template v-slot:accessTime="slotData">
                                <el-button
                                    type="text"
                                    @click="handleDetailClick(slotData.data)"
                                >
                                {{slotData.data.accessTime.split(',').length > 3 ? slotData.data.accessTime.split(',').slice(0, 3).join(','): slotData.data.accessTime}}{{ slotData.data.accessTime.split(',').length > 3 ?  '···' : ''}}
                                </el-button>
                            </template>
                            <template v-slot:operation="slotData">
                                <el-button
                                    type="text"
                                    v-hasPermi="['accessGroup:edit']"
                                    @click="edit(slotData.data)"
                                    >编辑</el-button
                                >
                                <el-button
                                    type="text"
                                    v-hasPermi="['accessGroup:delete']"
                                    @click="del([{id: slotData.data.id}])"
                                    >删除</el-button
                                >
                                <el-button
                                    type="text"
                                    v-hasPermi="['accessGroup:copy']"
                                    @click="copy(slotData.data.id)"
                                    >复制</el-button
                                >
                            </template>
                        </table-data>
                        <!-- 分页组件 -->
                        <pagination
                            :total="total"
                            :page.sync="listQuery.pageNum"
                            :limit.sync="listQuery.pageRow"
                            @pagination="getList"
                        />
                    </div>
                    <div v-if="role == 'student'">
                        <!-- 表格 -->
                        <table-data
                            id="peopleTable"
                            ref="peopleTable"
                            :key="peopleTableKey"
                            v-loading="peopleLoadingTable"
                            :config="peopleTable_config"
                            :tableData="peopleTable_data"
                        >
                            <template v-slot:accessTime="slotData">
                                <el-button
                                    type="text"
                                    @click="handleDetailPeopleClick(slotData.data)"
                                >
                                    {{slotData.data.accessTime.split(',').length > 3 ? slotData.data.accessTime.split(',').slice(0, 3).join(','): slotData.data.accessTime}}{{ slotData.data.accessTime.split(',').length > 3 ?  '···' : ''}}
                                </el-button>
                            </template>    
                            <template v-slot:operation="slotData">
                                <el-button
                                    type="text"
                                    v-hasPermi="['accessGroup:peopleList:edit']"
                                    @click="editPeople(slotData.data)"
                                    >编辑</el-button
                                >
                                <el-button
                                    type="text"
                                    v-hasPermi="['accessGroup:peopleList:delete']"
                                    @click="delPeople(slotData.data)"
                                    >删除</el-button
                                >
                            </template>
                        </table-data>
                        <!-- 分页组件 -->
                        <pagination
                            :total="peopleTotal"
                            :page.sync="peopleListQuery.pageNum"
                            :limit.sync="peopleListQuery.pageRow"
                            @pagination="getPeopleList"
                        />
                    </div>
                </div>
                <!-- 添加/编辑 -->
                <div
                    v-else
                    class="add-edit"
                >
                    <div class="common-form-layout">
                        <global-page-back
                            :detailPageTitle="
                                ruleForm.id === '' ? '添加出入组' : '编辑出入组'
                            "
                            @handlerGlobalType="resetForm"
                        >
                        </global-page-back>
                        <div class="common-form-wrapper content-bg">
                            <div
                                class="common-form-main common-form-layout--scroll"
                            >
                                <div class="header">
                                    {{
                                        ruleForm.id === ""
                                            ? "添加出入组"
                                            : "编辑出入组"
                                    }}
                                </div>
                                <div
                                    class="common-form-view"
                                    style="text-align: left; width: 880px"
                                >
                                    <!-- 表单内容 -->
                                    <el-form
                                        :model="ruleForm"
                                        :rules="rules"
                                        ref="ruleForm"
                                        label-width="130px"
                                        label-position="right"
                                        class="content common-form"
                                    >
                                        <el-form-item
                                            label="组名称"
                                            prop="groupName"
                                        >
                                            <el-input
                                                v-if="!isPeopleEdit"
                                                v-model="ruleForm.groupName"
                                                clearable
                                                placeholder="请输入出入组名称"
                                                show-word-limit
                                                :maxlength="30"
                                                style="width: 520px"
                                            />
                                            <el-select
                                                v-if="isPeopleEdit"
                                                ref="groupSelect"
                                                v-model="ruleForm.groupName"
                                                style="width: 520px"
                                                placeholder="请选择"
                                                @change="changeGroup($event)"
                                            >
                                                <el-option
                                                    v-for="it in groupList"
                                                    :key="it.groupId"
                                                    :label="it.groupName"
                                                    :value="it.groupId"
                                                />
                                            </el-select>
                                            <div class="people_tip" v-if="isPeopleEdit && isDidiableGroup">该出入组不可单独编辑该{{ruleForm.userPopulation == '1' ? '员工' : '学生'}}，因为人员是以{{ruleForm.userPopulation == '1' ? '部门' : '班级'}}方式添加</div>
                                        </el-form-item>
                                        <el-form-item
                                            label="出入类型"
                                            prop="accessType"
                                        >
                                            <el-radio
                                                v-model="ruleForm.accessType"
                                                label="1"
                                                >出</el-radio
                                            >
                                            <el-radio
                                                v-model="ruleForm.accessType"
                                                label="2"
                                                >入</el-radio
                                            >
                                            <el-radio
                                                v-model="ruleForm.accessType"
                                                label="3"
                                                >不区分出入</el-radio
                                            >
                                        </el-form-item>
                                        <el-form-item
                                            label="使用人群"
                                            prop="userPopulation"
                                        >
                                            <el-radio-group
                                                v-model="
                                                    ruleForm.userPopulation
                                                "
                                                @change="changeScoped"
                                                :disabled="isPeopleEdit"
                                            >
                                                <el-radio label="1"
                                                    >员工</el-radio
                                                >
                                                <el-radio label="2"
                                                    >学生</el-radio
                                                >
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item
                                            v-if="
                                                ruleForm.userPopulation == '2'
                                            "
                                            label="学生类型"
                                            prop="scope"
                                        >
                                            <el-radio-group
                                                v-model="ruleForm.scope"
                                                @change="handleScopeChange"
                                            >
                                                <el-radio label="3"
                                                    >全部</el-radio
                                                >
                                                <el-radio label="1"
                                                    >走读</el-radio
                                                >
                                                <el-radio label="0"
                                                    >住宿</el-radio
                                                >
                                                <el-radio label="2"
                                                    >半读</el-radio
                                                >
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item
                                            label="人员"
                                            prop="showName"
                                        >
                                            <div v-if="!isPeopleEdit">
                                                <div
                                                    style="
                                                        width: 750px;
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: center;
                                                    "
                                                >
                                                    <div
                                                        :readonly="editLoading"
                                                        class="focusPeople"
                                                        @click="showTreeDialog"
                                                    >
                                                        <div class="all_people">
                                                            <div
                                                                v-for="(
                                                                    item, index
                                                                ) in ruleShowNameArr"
                                                                :key="index"
                                                                class="each_people"
                                                            >
                                                                {{ item }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                ruleShowNameArr &&
                                                                ruleShowNameArr.length >
                                                                    11
                                                            "
                                                            class="elipls"
                                                        >
                                                            ...
                                                        </div>
                                                        <div
                                                            class="people_num"
                                                            v-loading="editLoading"
                                                            element-loading-spinner="el-icon-loading"
                                                        >
                                                            {{
                                                                peopleSelectedNum
                                                            }}人
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="import_btn"
                                                    @click="importPeople"
                                                    v-if="
                                                        ruleShowNameArr &&
                                                        ruleShowNameArr.length == 0
                                                    "
                                                >
                                                    导入人员
                                                </div>
                                            </div>
                                            <div v-else>{{ ruleForm.showName }}</div>
                                        </el-form-item>
                                        <el-form-item
                                            label="时间属性"
                                            style="position: relative;"
                                        >
                                        <span style="color: #e63a2e;position: absolute; top: 0;left: -91px;">*</span>    
                                        <div style="color: #606266;" :key="groupTimeTypeNameKey">{{ ruleForm.groupTimeTypeName }}</div>
                                        </el-form-item>
                                        <el-form-item
                                            label="时间设置"
                                            style="margin-bottom: 0"
                                        >
                                            <div class="time_group_add">
                                                <div
                                                    @click="addTimeGroup"
                                                    style="padding-top: 5px"
                                                >
                                                    <i
                                                        class="el-icon-circle-plus"
                                                    ></i>
                                                </div>
                                                <span class="add_time_tip"
                                                    >最多可添加5组出入时间</span
                                                >
                                            </div>
                                            <div
                                                :key="key"
                                                style="
                                                    display: flex;
                                                    justify-content: space-between;
                                                    flex-wrap: wrap;
                                                "
                                            >
                                                <div
                                                    class="push_each"
                                                    v-for="(
                                                        item, index
                                                    ) in pushArr"
                                                    :key="index"
                                                >
                                                    <div class="each_setting">
                                                        <el-time-picker
                                                            :disabled="
                                                                editLoading
                                                            "
                                                            is-range
                                                            v-model="item.time"
                                                            range-separator="至"
                                                            start-placeholder="开始时间"
                                                            end-placeholder="结束时间"
                                                            value-format="HH:mm"
                                                            format="HH:mm"
                                                            @change="
                                                                changeTime(
                                                                    item.time,
                                                                    index,
                                                                )
                                                            "
                                                            style="width: 308px"
                                                        />
                                                        <div
                                                            @click="
                                                                handleDelete(
                                                                    index,
                                                                )
                                                            "
                                                            style="
                                                                padding-top: 3px;
                                                                margin-left: 8px;
                                                            "
                                                        >
                                                            <i
                                                                class="el-icon-remove"
                                                                v-if="
                                                                    pushArr.length >
                                                                    1
                                                                "
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="repeat_tip"
                                                        v-if="item.isShowTip"
                                                    >
                                                        时间重复，请重新选择
                                                    </div>
                                                </div>
                                            </div>
                                        </el-form-item>
                                        <el-form-item
                                            label="出入规则"
                                            prop="accessRule"
                                            style="position: relative"
                                        >
                                            <el-radio
                                                @change="changeAccessRule"
                                                v-model="ruleForm.accessRule"
                                                label="1"
                                                >每周</el-radio
                                            >
                                            <el-radio
                                                @change="changeAccessRule"
                                                v-model="ruleForm.accessRule"
                                                label="2"
                                                >自定义</el-radio
                                            >
                                        </el-form-item>
                                        <!-- 只有学生显示 -->
                                        <el-form-item
                                            v-if="userPopulationShow"
                                            label="出校需要接送人"
                                            prop="stuPick"
                                            style="position: relative"
                                        >
                                        <el-radio label="2"
                                            v-model="ruleForm.stuPick"
                                                    >否</el-radio
                                                >
                                                <el-radio label="1"
                                                    v-model="ruleForm.stuPick"
                                                    >是 </el-radio
                                                >

                                            <!-- <el-radio-group
                                                v-model="ruleForm.stuPick"
                                                @change="handleStuPickChange"
                                            >
                                                <el-radio label="2">否</el-radio>
                                                <el-radio label="1">是</el-radio>
                                            </el-radio-group> -->
                                        </el-form-item>
                                        <!-- 具体出入时间选择 -->
                                        <el-card>
                                            <!-- 出入规则每周 -->
                                            <div
                                                v-if="
                                                    ruleForm.accessRule === '1'
                                                "
                                                class="week-wrap"
                                            >
                                                <div
                                                    v-for="(
                                                        item, index
                                                    ) in weekDateList"
                                                    :key="item.prop"
                                                    :class="{
                                                        'first-week-content':
                                                            index === 0,
                                                        'week-content':
                                                            index !== 0,
                                                    }"
                                                    :style="{
                                                        'background-color':
                                                            item.sel
                                                                ? '#E5F0FF'
                                                                : '#F5F5F5',
                                                    }"
                                                >
                                                    <div
                                                        style="
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                        "
                                                    >
                                                        <span class="label">{{
                                                            item.prop
                                                        }}</span>
                                                        <span
                                                            v-if="
                                                                searchWeekArrRepeat(
                                                                    item,
                                                                )
                                                            "
                                                            class="repeat_tip_only_week"
                                                            >重复</span
                                                        >
                                                        <el-checkbox
                                                            v-model="item.sel"
                                                            @click="
                                                                changeWeekSel(
                                                                    index,
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            items, indexs
                                                        ) in item.value"
                                                        :key="`items${index}${indexs}`"
                                                    >
                                                        <el-time-picker
                                                            is-range
                                                            v-model="items.time"
                                                            value-format="HH:mm"
                                                            format="HH:mm"
                                                            prefix-icon="''"
                                                            style="
                                                                width: 100%;
                                                                margin-top: 10px;
                                                            "
                                                            :class="{
                                                                is_repeat: items.isShowWeekTip
                                                            }"
                                                            @change="
                                                                changeWeekTime(
                                                                    items,
                                                                    indexs,
                                                                    index,
                                                                )
                                                            "
                                                        />

                                                    </div>
                                                    <div
                                                        v-if="
                                                            item.value.time &&
                                                            item.value[0].time
                                                                .length == 0
                                                        "
                                                    >
                                                        <el-time-picker
                                                            is-range
                                                            v-model="item.value"
                                                            value-format="HH:mm"
                                                            format="HH:mm"
                                                            prefix-icon="''"
                                                            style="
                                                                width: 100%;
                                                                margin-top: 10px;
                                                            "
                                                            @change="
                                                                changeWeekTime(
                                                                    items,
                                                                    indexs,
                                                                    index,
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- 出入规则每月 -->
                                            <calendar
                                                v-else
                                                :can-select="true"
                                                :date-list="newMonthDateList"
                                                :timeRangeArr="pushArr"
                                                width="100%"
                                                :key="calendarKey"
                                                @dateSelected="
                                                    calendarClick(arguments)
                                                "
                                            >
                                                <slot slot-scope="data">
                                                    <div
                                                        v-if="
                                                            data.data
                                                                .timeRangeArr
                                                        "
                                                    >
                                                        <div
                                                            v-if="
                                                                data.data
                                                                    .timeRangeArr
                                                                    .length > 1
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    data.data
                                                                        .isExpanded
                                                                "
                                                                class="show_time_box"
                                                                :class="{
                                                                    show_time_box1:
                                                                        data.dataIndex ==
                                                                            1 ||
                                                                        data.dataIndex ==
                                                                            5,
                                                                }"
                                                                @click.stop
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        items,
                                                                        indexs
                                                                    ) in data
                                                                        .data
                                                                        .timeRangeArr"
                                                                    :key="
                                                                        indexs
                                                                    "
                                                                >
                                                                    <el-time-picker
                                                                        v-show="
                                                                            data
                                                                                .data
                                                                                .isSelected
                                                                        "
                                                                        is-range
                                                                        v-model="
                                                                            data
                                                                                .data
                                                                                .timeRangeArr[
                                                                                indexs
                                                                            ]
                                                                                .time
                                                                        "
                                                                        value-format="HH:mm"
                                                                        prefix-icon="''"
                                                                        format="HH:mm"
                                                                        style="
                                                                            width: 100%;
                                                                            margin-top: 10px;
                                                                        "
                                                                        :class="{
                                                                            is_repeat:
                                                                                data
                                                                                    .data
                                                                                    .timeRangeArr[
                                                                                    indexs
                                                                                ]
                                                                                    .isRepeatDate,
                                                                        }"
                                                                        :key="
                                                                            monthTimeKey
                                                                        "
                                                                        @change="
                                                                            changeMonthTime(
                                                                                items,
                                                                                indexs,
                                                                                data.data,
                                                                            )
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div @click.stop>
                                                        <el-time-picker
                                                            v-show="
                                                                data.data
                                                                    .isSelected
                                                            "
                                                            is-range
                                                            v-model="
                                                                newMonthDateList.find(
                                                                    (it) =>
                                                                        it.date ==
                                                                        data
                                                                            .data
                                                                            .date,
                                                                )?.timeArr[0]
                                                                    .time
                                                            "
                                                            value-format="HH:mm"
                                                            prefix-icon="''"
                                                            format="HH:mm"
                                                            :class="{
                                                                is_repeat:
                                                                    newMonthDateList.find(
                                                                        (it) =>
                                                                            it.date ==
                                                                            data
                                                                                .data
                                                                                .date,
                                                                    )
                                                                        ?.timeArr[0]
                                                                        .isRepeatDate,
                                                            }"
                                                            @change="
                                                                changeMonthTime1(
                                                                    newMonthDateList.find(
                                                                        (it) =>
                                                                            it.date ==
                                                                            data
                                                                                .data
                                                                                .date,
                                                                    )
                                                                        ?.timeArr[0]
                                                                        .time,
                                                                    0,
                                                                    data.data,
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                </slot>
                                                <template
                                                    v-slot:repeat="repeatData"
                                                >
                                                    <span
                                                        v-if="
                                                            searchArrRepeat(
                                                                repeatData,
                                                            )
                                                        "
                                                        class="repeat_tip_only"
                                                        >重复</span
                                                    >
                                                </template>
                                            </calendar>
                                        </el-card>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                        <form-submit-btn>
                            <div slot="formSubmitBtn">
                                <el-button
                                    :disabled="editLoading || isDidiableGroup"
                                    @click="submitForm"
                                    type="primary"
                                    >保存</el-button
                                >
                                <el-button
                                    @click="resetForm"
                                    type="enquiry"
                                    >取消</el-button
                                >
                            </div>
                        </form-submit-btn>
                    </div>
                </div>
                <!--人员选择弹窗-->
                <xk-tree-shape
                    v-if="dialogObj.dialogVisible"
                    :title="dialogObj.title"
                    :show="dialogObj.dialogVisible"
                    :list="selTreeOptList"
                    :selectObjData="selectObjData"
                    :initLoading="treeShapeInitLoading"
                    @getNextLevelTreeList="getNextLevelTreeList"
                    @determine="determine"
                    @closeDialog="closeTreeDialog"
                >
                </xk-tree-shape>
                <dialog-wrapper
                    :dialog-obj="importDialog"
                    @handleClose="handleImportClose"
                >
                    <div class="import-wrap">
                        <div>导入方法</div>
                        <div style="margin-top: 10px;margin-bottom: 6px;">
                            <span>1、请 </span>
                            <xk-button
                                type="primary"
                                text
                                margin-left="0"
                                @click="downloadTemplate"
                                >下载模板
                            </xk-button>
                            <span> 按照模板填充数据，上传填好的文件</span>
                        </div>
                        <el-upload
                            ref="upload"
                            :action="action"
                            :headers="headers"
                            :data="{
                                schoolId: schoolId,
                                type: ruleForm.userPopulation,
                            }"
                            :file-list="fileList"
                            accept=".xlsx,.xls"
                            :on-change="handleImportChange"
                            :on-remove="handleImportRemove"
                            :show-file-list="true"
                            :auto-upload="false"
                        >
                            <span>2、</span>
                            <xk-button
                                type="primary"
                                text
                                margin-left="0"
                                >上传文件
                            </xk-button>
                        </el-upload>
                    </div>
                    <div style="text-align: right;margin-top: 30px;">
                        <el-button @click="handleImportClose">取消</el-button>
                        <el-button
                            type="primary"
                            :loading="importLoading"
                            @click="handleSubmitImport"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialog-obj="setDialog"
                    v-if="setDialog.dialogVisible"
                    @handleClose="handleSetClose"
                    class="set-dialog-box"
                >
                    <div class="set-wrap">
                        <div class="teacher-type">
                            <div class="teacher_title">员工</div>
                            <div class="teacher_radio">
                                <el-radio v-model="teacherType" :disabled="teacherCount && teacherCount > 0" :label="0">允许时间</el-radio>
                                <el-radio v-model="teacherType" :disabled="teacherCount && teacherCount > 0" :label="1">禁止时间</el-radio>
                            </div>
                        </div>
                        <div class="student-type">
                            <div class="teacher_title">学生</div>
                            <div class="teacher_radio">
                                <el-radio v-model="studentType" :disabled="studentCount && studentCount > 0" :label="0">允许时间</el-radio>
                                <el-radio v-model="studentType" :disabled="studentCount && studentCount > 0" :label="1">禁止时间</el-radio>
                            </div>
                        </div>
                    </div>    
                    <div class="set_tip_wrap">
                        <div class="each_tip">1、选择允许时间，该角色可以在允许时间内出入</div>
                        <div class="each_tip">2、选择禁止时间，该角色可以在非禁止时间内出入</div>
                        <div class="each_tip">3、若某角色不存在出入组，可以修改该角色的时间属性，否则不可修改</div>
                        <div class="each_tip">4、所有该角色的出入组时间属性一致</div>
                    </div>
                    <div style="text-align: right;margin-top: 30px;">
                        <el-button @click="handleSetClose">取消</el-button>
                        <el-button
                            type="primary"
                            :loading="setLoading"
                            @click="handleSubmitSet"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialog-obj="detailDialog"
                    v-if="detailDialog.dialogVisible"
                    @handleClose="handleDetailClose"
                    class="detail-dialog-box"
                >
                    <table-data
                        v-loading="detailLoadingTable"
                        ref="detailTable"
                        :config="detail_table_config"
                        :tableData="detail_table_data"
                    >
                    </table-data>
                    <div style="text-align: right;margin-top: 20px;">
                        <el-button
                            type="primary"
                            @click="handleDetailClose"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialog-obj="detailPeopleDialog"
                    v-if="detailPeopleDialog.dialogVisible"
                    @handleClose="handleDetailPeopleClose"
                    class="detail-dialog-box"
                >
                    <el-table
                        :data="detail_people_table_data"
                        border
                        v-loading="detailPeopleLoadingTable"
                        ref="detailTable"
                        :span-method="objectSpanMethod"
                        :cell-style="cellStyle"
                        :header-cell-style="headerCellStyle"
                        :max-height="600"
                        class="table-content"
                    >
                        <el-table-column
                            prop="groupName"
                            label="出入组名称"
                            width="180"
                            align="center"
                            class-name="first-column"
                        ></el-table-column>
                        <el-table-column
                            prop="name"
                            label="日期"
                            width="140"
                            align="center"
                            class-name="first-column"
                        ></el-table-column>
                        <el-table-column
                            prop="timeText"
                            label="时间点"
                            width="500"
                            type="popoverText"
                            align="center"
                            class-name="first-column"
                        ></el-table-column>
                    </el-table>

                    <div style="text-align: right;margin-top: 20px;">
                        <el-button
                            type="primary"
                            @click="handleDetailPeopleClose"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialog-obj="delPeopleDialog"
                    v-if="delPeopleDialog.dialogVisible"
                    @handleClose="handleDelPeopleClose"
                    class="del-dialog-box"
                >
                    <div class="tip_title">确定要删除该人员的出入规则吗？</div>    
                    <div class="tip_content">1、该人员将从原出入组移除（若有多个，对所有出入组操作）</div>
                    <div class="tip_content">2、仅针对人员添加不是部门的出入组</div>
                    <div style="text-align: right;margin-top: 30px;">
                        <el-button @click="handleDelPeopleClose">取消</el-button>
                        <el-button
                            type="primary"
                            @click="handleDelPeopleSubmit"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialog-obj="editPeopleDialog"
                    v-if="editPeopleDialog.dialogVisible"
                    @handleClose="handleEditPeopleClose"
                    class="del-dialog-box"
                >
                    <div class="tip_title">确定要单独编辑该人员吗？</div>    
                    <div class="tip_content1">
                        <div>1、</div>
                        <div>编辑完成后，该人员自动将从原出入组移除，独立作为一个新出入组 可在组列表中查看</div>
                    </div>
                    <div class="tip_content">2、编辑仅针对人员添加不是部门的出入组</div>
                    <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 40px;">
                        <div class="tip_checked_class">
                            <el-checkbox v-model="noMorePromptsChecked" @change="changeNoMorePrompts">不再提示</el-checkbox>
                        </div>
                        <div>
                            <el-button @click="handleEditPeopleClose">取消</el-button>
                            <el-button
                                type="primary"
                                @click="handleEditPeopleSubmit"
                                >确定</el-button
                            >
                        </div>
                    </div>
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    debounce,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import SelTree from "./Sub/SelTree1";
import ExpandFilter from "./Sub/ExpandFilter";

import {getToken} from "@/libs/auth";
import {mapState} from "vuex";
import {CampusSafetyModel} from "@/models/CampusSafety";
import {CampusOAModel} from "@/models/CampusOA.js";
import calendar from "./CampusSafetyAccessManager/accessSetting/calendar";
import {CommonModel} from "@/models/Common.js";
import onResize from "@/mixins/onResize";
import GlobalPageBack from "./Sub/GlobalPageBack/index";
import FormSubmitBtn from "./Sub/FormSubmitBtn/index.vue";
import {downloadFile, listToTree, hasPermission, removeTreeShapeDialog, treeToList} from "@/libs/utils.js";

export default {
    mixins: [onResize],
    name: "CampusSafetyAccessManageAccessSettingScroll",
    components: {
        ExpandFilter,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        calendar,
        SelTree,
        GlobalPageBack,
        FormSubmitBtn,
    },
    data() {
        return {
            role: 'group',
            tableKey: 0,
            listQuery: {
                schoolId: "",
                pageNum: 1,
                pageRow: 20,
                groupName: "",
                accessType: "",
                userPopulation: "",
            },
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "组名称",
                        key: "groupName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "类型",
                        key: "accessType",
                        list: [
                            {
                                label: "出校",
                                value: 1,
                            },
                            {
                                label: "入校",
                                value: 2,
                            },
                            {
                                label: "不区分出入校",
                                value: 3,
                            },
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "使用人群",
                        key: "userPopulation",
                        list: [
                            {
                                label: "员工",
                                value: 1,
                            },
                            {
                                label: "学生",
                                value: 2,
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["accessGroup:list"],
                    },
                ],
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "组名称",
                        prop: "groupName",
                        type: "popoverText",
                        align: "center",
                    },
                    {
                        label: "包含人数",
                        prop: "personNum",
                        type: 'function',
                        align: "center",
                        callBack: (row) => {
                            if (Number(row.personNum) > 0) {
                                return row.personNum
                            } else if (Number(row.personNum) == 0) {
                                return '0'
                            } else {
                                return '-'
                            }
                        }
                    },
                    {
                        label: "出入类型",
                        prop: "accessType",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return {
                                1: "出校",
                                2: "入校",
                                3: "不区分出入校",
                            }[+row.accessType];
                        },
                    },
                    {
                        label: "使用人群",
                        prop: "userPopulation",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return {
                                1: "员工",
                                2: "学生",
                            }[+row.userPopulation];
                        },
                    },
                    {
                        label: "时间属性",
                        prop: "userPopulation",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            if (row.userPopulation == 1) {
                                return {
                                    0: "允许时间",
                                    1: "禁止时间",
                                }[+row.teacherTimeType];
                            } else if (row.userPopulation == 2) {
                                return {
                                    0: "允许时间",
                                    1: "禁止时间",
                                }[+row.studentTimeType];
                            }
                        },
                    },
                    {
                        label: "时间设置",
                        prop: "accessTime",
                        type: "popoverText",
                        slotName: "accessTime",
                        type: "slot",
                        labelWidth: "300px",
                        align: "center",
                    },
                    {
                        label: "启用状态",
                        prop: "status",
                        type: "switch",
                        special: "",
                        disabled: !hasPermission(["accessGroup:edit"]),
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                ],
                check: true,
                height: "",
                isCloseRow: {key: "status", value: "0"},
            },
            // 表格数据
            table_data: [],
            groupTimeTypeName: "",
            groupList: [],  // 组别
            peoplegroupName: "",
            currentPeopleGroupName: "", // 人员维度的编辑的组名称
            currentPeopleGroupId: "", // 人员维度的编辑的组Id
            peopleEditObj: {}, // 人员维度的编辑对象
            isPeopleEdit: false,  // 是否是人员维度的编辑
            // 人员的维度数据-----------
            peopleTableKey: 0,
            peopleListQuery: {
                schoolId: "",
                pageNum: 1,
                pageRow: 20,
                userPopulation: "",
                personName: "",
                organIdList: [],
            },
            peopleTotal: 0,  // 人员的维度
            // 头部筛选
            peopleFormData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "姓名",
                        key: "personName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "2",
                        placeholder: "使用人群",
                        clearable: false,
                        key: "userPopulation",
                        list: [
                            {
                                label: "员工",
                                value: 1,
                            },
                            {
                                label: "学生",
                                value: 2,
                            },
                        ],
                    },
                    {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "organIdList",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        }
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["accessGroup:peopleList:query"],
                    },
                ],
            },
            // 表格配置
            peopleTable_config: {
                thead: [
                    {
                        label: "使用人群",
                        prop: "userPopulation",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return {
                                1: "员工",
                                2: "学生",
                            }[+row.userPopulation];
                        },
                    },
                    {
                        label: "姓名",
                        prop: "personName",
                        type: "popoverText",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "班级",
                        prop: "organName",
                        align: "center",
                        type: "popoverText",
                        type: "tooltipColumn",
                        splitSign: ",",
                        labelWidth: "200px",
                    },
                    {
                        label: "时间属性",
                        prop: "userPopulation",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            if (row.userPopulation == 1) {
                                return {
                                    0: "允许时间",
                                    1: "禁止时间",
                                }[+row.teacherTimeType];
                            } else if (row.userPopulation == 2) {
                                return {
                                    0: "允许时间",
                                    1: "禁止时间",
                                }[+row.studentTimeType];
                            }
                        },
                    },
                    {
                        label: "时间设置",
                        prop: "accessTime",
                        type: "popoverText",
                        slotName: "accessTime",
                        type: "slot",
                        labelWidth: "300px",
                        align: "center",
                    },
                    {
                        label: "更新时间",
                        prop: "updateTime",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            if (row.updateTime) {
                                return row.updateTime.substring(0, 16);
                            }
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                ],
                check: false,
                height: "",
                isCloseRow: {key: "status", value: "0"},
            },
            // 表格数据
            peopleTable_data: [],
            detailLoadingTable: false,
            // 时间设置详情配置
            detail_table_config: {
                thead: [
                    {
                        label: "日期",
                        prop: "name",
                        align: "center",
                        labelWidth: "150px",
                    },
                    {
                        label: "时间点",
                        prop: "timeText",
                        type: "popoverText",
                        labelWidth: "500px",
                        align: "center",
                    },
                ],
                check: false,
                border: true,
                maxHeight: "600px",
                leftEmpty: false,
                isCloseRow: {key: "status", value: "0"},
            },
            // 时间设置详情数据 -> 人员
            detail_table_data: [],
            detailPeopleLoadingTable: false,
            // 时间设置详情配置 -> 人员
            detail_people_table_config: {
                thead: [
                    {
                        label: "组名称",
                        prop: "groupName",
                        align: "center",
                        labelWidth: "180px",
                    },
                    {
                        label: "日期",
                        prop: "name",
                        align: "center",
                        labelWidth: "140px",
                    },
                    {
                        label: "时间点",
                        prop: "timeText",
                        type: "popoverText",
                        labelWidth: "500px",
                        align: "center",
                    },
                ],
                check: false,
                border: true,
                maxHeight: "600px",
                leftEmpty: false,
                isCloseRow: {key: "status", value: "0"},
            },
            // 时间设置详情数据 -> 人员
            detail_people_table_data: [],
            spanArrOne: [],
            checked: false, // 只看人数为1的组
            // 添加编辑显示
            showAddEdit: false,
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            peopleLoadingTable: true,
            // 新增
            ruleForm: {
                id: "",
                schoolId: "",
                groupName: "", // 出入组名称
                accessType: "1", // 出入类型  1出校 2入校 3不区分出入校
                accessRule: "1", // 出入规则  1每周 2自定义
                time: "", // 出入时间，辅助星期和日历选择，仅添加时展示
                ruleInfo: [], // 出入时间信息
                organInfo: [], // 选中机构信息
                showName: "", // 人员输入框展示使用
                userPopulation: "1",
                scope: "3", // 学生适用范围 0住宿 1走读 2半读 3全部
                groupTimeTypeName: "允许时间", // 时间属性
            },
            rules: {
                groupName: [
                    {
                        required: true,
                        message: "请输入出入组名称",
                        trigger: ["change", "blur"],
                    },
                ],
                accessType: [
                    {
                        required: true,
                        message: "请选择出入类型",
                        trigger: ["change", "blur"],
                    },
                ],
                accessRule: [
                    {
                        required: true,
                        message: "请选择出入规则",
                        trigger: ["change", "blur"],
                    },
                ],
                showName: [
                    {
                        required: true,
                        message: "请选择出入人员",
                        trigger: ["change", "blur"],
                    },
                ],
                userPopulation: [
                    {
                        required: true,
                        message: "请选择使用人群",
                        trigger: ["change", "blur"],
                    },
                ],
                scope: [
                    {
                        required: true,
                        message: "请选择学生类型",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            // 表格选中
            tableListSel: [],
            // 日历出入时间
            monthDateList: {},
            newMonthDateList: [],
            // 星期出入时间
            weekDateList: [
                {
                    prop: "周一",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
                {
                    prop: "周二",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
                {
                    prop: "周三",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
                {
                    prop: "周四",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
                {
                    prop: "周五",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
                {
                    prop: "周六",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
                {
                    prop: "周日",
                    value: [
                        {
                            time: "",
                            isShowWeekTip: false,
                        },
                    ],
                    sel: true,
                },
            ],
            // 人员选择弹窗
            dialogObj: {
                title: "选择部门/教职工",
                dialogVisible: false,
                width: "600px",
                key: 1,
            },
            // 树形组件属性选择器
            teacherData: [], // 教师数据
            studentData: [], // 学生数据
            // 请求组织机构数据
            treeData: {
                schoolId: "",
                type: "",
                parentId: "",
                name: "",
                organType: "", //组织类型，类型包含organ时必传
                unionId: "", //部门组合id
                scope: "3", // 学生适用范围 0住宿 1走读 2半读 3全部
            },
            /**
             * 组织机构在不同的适用人群变化不同的值
             * facultyStaff 教师部门
             * studentParents 学生班级
             * */
            facultyStaff: {
                type: "teacher,organ",
                organType: "1,5",
            },
            studentParents: {
                type: "student,organ",
                organType: "2,3,4,5",
            },
            // 已经选择属性数据对象 selectObjData
            selectObjData: [],
            selTreeOptList: [], //树形返回数据
            treeShapeInitLoading: false,
            peopleSelectedNum: 0, // 组织架构选中人数
            editLoading: false, // 编辑页loading
            importLoading: false, // 导入按钮加载状态
            importDialog: {
                title: "导入",
                dialogVisible: false,
                width: "auto",
            },
            setLoading: false, // 时间属性设置的确定按钮
            setDialog: {
                title: "时间属性设置",
                dialogVisible: false,
                width: "600px",
            },
            detailDialog: {
                title: "时间设置详情",
                dialogVisible: false,
                width: "auto",
            },
            detailPeopleDialog: {
                title: "时间设置详情",
                dialogVisible: false,
                width: "auto",
            },
            delPeopleDialog: {
                title: "提示",
                dialogVisible: false,
                width: "520px",
            },
            editPeopleDialog: {
                title: "提示",
                dialogVisible: false,
                width: "520px",
            },
            noMorePromptsChecked: false, // 不再提示
            delId: "",
            delPersonObj: {},
            fileList: [],
            pushArr: [
                {
                    time: "",
                    isShowTip: false,
                },
            ],
            key: 1,
            isShowWeekTip: false, // 选择周的时间段是否重叠
            calendarKey: 1,
            monthTimeKey: 1,
            exportBtnList: ['accessGroup:exportAll','accessGroup:export'],
            exportAllLoading: false,
            exportListLoading: false,
            exportBtnLoading: false,
            studentType: 0,  // 学生的时间属性 0 允许 1 禁用
            studentCount: 0,  // 学生的人数
            teacherType: 0,// 员工的时间属性 0 允许 1 禁用
            teacherCount: 0,  // 员工的人数
            isDidiableGroup: false,
            groupTimeTypeNameKey: 1,
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            permissions: (state) => state.permissions,
            userId: (state) => state.userId,
        }),
        backUrl() {
            return require("@/assets/images/back_.png");
        },
        exportUrl() {
            return require("@/assets/images/export.png");
        },
        ruleShowNameArr() {
            if (!this.ruleForm.showName) {
                return [];
            } else {
                let arr = this.ruleForm.showName.split(",");
                // 如果长度超过10个，则至展示前十个
                const LimtNum = 12;
                if (arr.length > LimtNum) {
                    arr = arr.slice(0, LimtNum);
                }
                return arr;
            }
        },
        timePickerGroup_add() {
            return require("@/assets/images/characterGroup_add.png");
        },
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
        exportState(){
            let aaa = true;
            aaa = this.exportBtnList.every((permission) => this.permissions.includes(permission))
            console.log('aaa',aaa);
            return aaa
        },
        // 表单中是否展示 "出校需要接送人" 项
        userPopulationShow() {
            // 当使用人群userPopulation为学生且出入类型accessType不为"入"时，展示
            return this.ruleForm.userPopulation == '2' && this.ruleForm.accessType != '2'
        },
        stuN() {
            return require("@/assets/images/groupN.png");
        },
        stuY() {
            return require("@/assets/images/groupY.png");
        },
        teaN() {
            return require("@/assets/images/peopleN.png");
        },
        teaY() {
            return require("@/assets/images/peopleY.png");
        },
        isAccessGroupSet(){
            return hasPermission(["accessGroup:set"])
        },
        isAccessGroupOnlyOne(){
            return hasPermission(["accessGroup:onlyOne"])
        },
        isAccessGroupPeopleList(){
            return hasPermission(["accessGroup:peopleList"])
        },
        cellStyle() {
            return {
                backgroundColor: '#ffffff',
                color: '#595959',
                borderColor: '#E0E0E0',
            }
        },
        headerCellStyle() {
            return {
                backgroundColor: '#EFFCFD',
                color: '#595959',
                borderColor: '#E0E0E0',
                fontWeight: 'bold',
            }
        }
    },
    async created() {
        this.$eventDispatch("setGlobalPageType", "list");
        this.ruleForm.schoolId = this.listQuery.schoolId= this.peopleListQuery.schoolId = this.schoolId;
        this.treeData.schoolId = this.schoolId;
        await this.getGroupTimeList();
        // 获取列表数据
        this.getList();
        // 组织机构默认选中教职工
        this.whoCanInitiate("1");
        // this.getTreeData("init");
        this.setCreatedTreeData(this.selTreeOptList);
    },
    mounted() {},
    methods: {
        /**
         * @Description: 只看人数为1的组触发的查询事件
         * @Author: 闫乔
         * @Date: 2024-10-08 14:29:41
         * @param {*} val
         */        
        changeOnlyOne(val) {
            this.getList(1);
        },
        /**
         * @Description: 时间属性设置
         * @Author: 闫乔
         * @Date: 2024-10-08 14:31:39
         */        
        async handleSet() {
            await this.getGroupTimeList();
            this.setDialog.dialogVisible = true;
        },
        /**
         * @Description: 时间属性设置的确定
         * @Author: 闫乔
         * @Date: 2024-10-08 16:32:07
         */        
        handleSubmitSet() {
            this.setLoading = true;
            let setObj = {
                schoolId: this.schoolId,
                config: {
                    teacher: this.teacherType,
                    student: this.studentType
                }
            }
            let campusSafety = new CampusSafetyModel();
            campusSafety.setGroupTimeType(setObj).then((res) => {
                this.handleRes(res, () => {
                    this.getGroupTimeList();
                    // 获取列表数据
                    setTimeout(() => {
                        this.$message.success("设置成功");
                        this.getList();
                    },500);
                    this.setLoading = false;
                    this.handleSetClose();
                })
            });
        },
        /**
         * @Description: 关闭时间属性设置弹窗
         * @Author: 闫乔
         * @Date: 2024-10-08 16:31:36
         */        
        handleSetClose() {
            this.setDialog.dialogVisible = false;
        },
        /**
         * @Description: 把时间段处理成列表所需要的格式 - 组
         * @Author: 闫乔
         * @Date: 2024-10-12 15:46:41
         * @param {*} data
         */        
        mergeTimeData(data) {
            const result = [];
            const map = new Map();
        
            for (const item of data) {
                const key = item.name;
                const value = item.timeText;
            
                if (!map.has(key)) {
                    map.set(key, value);
                } else {
                    const oldValue = map.get(key);
                    map.set(key, `${oldValue}、${value}`);
                }
            }
        
            for (const [key, value] of map) {
            result.push({ name: key, timeText: value });
            }
        
            return result;
        },
        /**
         * @Description: 把时间段处理成列表所需要的格式 -> 人员
         * @Author: 闫乔
         * @Date: 2024-10-12 15:46:22
         * @param {*} data
         */        
        peopleMergeTimeData(data, group) {
            
            const result = [];
            const map = new Map();
        
            for (const item of data) {
                const key = item.name;
                const value = item.timeText;
            
                if (!map.has(key)) {
                    map.set(key, value);
                } else {
                    const oldValue = map.get(key);
                    map.set(key, `${oldValue}、${value}`);
                }
            }
        
            for (const [key, value] of map) {
                result.push({ name: key, timeText: value, groupName: group });
            }
            
            return result;
        },
        /**
         * @Description: 点击时间设置查看详情
         * @Author: 闫乔
         * @Date: 2024-10-09 17:32:39
         * @param {*} row
         */        
        handleDetailClick(row) {
            if (row.detailAccessTime) {
                let newarr = row.detailAccessTime.split("、");
                newarr.pop();
                let testArr = newarr.map(item => {
                    let [day, times] = item.split(" ");
                    return {
                        name: day,
                        timeText: times
                    };
                });
                
                let detailArray = this.mergeTimeData(testArr)
                this.detail_table_data = detailArray;
            }
            
            this.detailDialog.dialogVisible = true;
        },
        /**
         * @Description: 时间设置详情弹窗的关闭
         * @Author: 闫乔
         * @Date: 2024-10-09 17:31:53
         */        
        handleDetailClose() {
            this.detailDialog.dialogVisible = false;
        },
        /**
         * @Description: 初始化人员维度的页面数据
         * @Author: 闫乔
         * @Date: 2024-10-12 14:21:25
         */        
        getPersonData() {
            if (this.peopleListQuery.userPopulation == '') {
                this.peopleFormData.data.forEach((item) => {
                    // 人员默认是学生
                    if (item.key == 'userPopulation') {
                        item.value = 2;
                        this.peopleListQuery.userPopulation = item.value;
                    }
                })
                let selectFormData = {
                    type: "cascader",
                    label: "",
                    value: [],
                    placeholder: "班级",
                    key: "organIdList",
                    list: [],
                    cascaderProps: {
                        label: "name",
                        multiple: true,
                        value: "id",
                        emitPath: false
                    }
                };
                this.peopleFormData.data.splice(2, 1, selectFormData);
                this.getOrg();  // 获取班级下拉数据
            } else {
                if (this.peopleListQuery.userPopulation == '1') {
                    this.getOrgList();
                } else {
                    this.getOrg();
                }
            }
            this.getPeopleList(); // 获取列表数据
            this.getPeopleDoNotPrompt(); // 获取人员编辑是否不再提示
        },
        /**
         * @Description: 切换组/人员维度
         * @Author: 闫乔
         * @Date: 2024-10-08 15:31:59
         * @param {*} role
         */        
        async setRole(role) {
            if (this.role == role) {
                return
            }
            this.role = role;
            // 获取列表数据
            await this.getGroupTimeList();
            if (role == 'group') {
                // 组维度
                this.getList();
            } else {
                // 人员维度
                this.getPersonData();
            }
            // 组织机构默认选中教职工
            this.whoCanInitiate("1");
            this.setCreatedTreeData(this.selTreeOptList);
            this.isPeopleEdit = false;
        },      
        /**
         * @Description: 获取学生表格列表数据
         * @Author: 闫乔
         * @Date: 2024-10-09 11:30:37
         * @param {*} t
         */        
        getPeopleList(t) {
            this.peopleLoadingTable = true;
            if (t === 1) {
                this.peopleListQuery.pageNum = 1;
            }
            let campusSafety = new CampusSafetyModel();
            campusSafety.getGroupPersonList(this.peopleListQuery).then((res) => {
                this.handleRes(res, () => {
                    for (let item of res.data.data.rows) {
                        for (let item1 of item.group) {
                            item1.accessTimeArray = "";
                            if (item1.ruleInfo) {
                                for (let item2 of item1.ruleInfo) {
                                    let timeRangeArr = [];
                                    for (const key of Object.keys(item2)) {
                                        if (key.startsWith("startTime")) {
                                            const index = parseInt(
                                                key.replace("startTime", ""),
                                            );
                                            if (
                                                String(index) &&
                                                String(index) != "NaN"
                                            ) {
                                                timeRangeArr.push({
                                                    startTime: item2[key],
                                                    endTime: item2[`endTime${index}`],
                                                });
                                            } else {
                                                timeRangeArr.push({
                                                    startTime: item2[key],
                                                    endTime: item2[`endTime`],
                                                });
                                            }
                                        }
                                    }
                                    timeRangeArr.forEach((items, indexs) => {
                                        item1.accessTimeArray +=
                                            item1.groupName +
                                            " " +
                                            item2.date +
                                            " " +
                                            items.startTime +
                                            "-" +
                                            items.endTime +
                                            "、";
                                    });
                                }
                                
                            }
                        }
                    }
                    
                    res.data.data.rows.forEach((item) => {
                        item.studentTimeType = this.studentType;
                        item.teacherTimeType = this.teacherType;
                    })
                    // 处理表头
                    this.setPeopleTableHeader();
                    this.peopleTable_data = res.data.data.rows;
                    this.peopleTotal = res.data.data.total;
                    this.peopleTableKey = this.peopleTableKey + 1;
                    this.peopleLoadingTable = false;
                    this.changeHandler();
                });
            });
        },
        
        /**
         * @Description: 点击时间设置查看详情
         * @Author: 闫乔
         * @Date: 2024-10-09 17:32:39
         * @param {*} row
         */        
         handleDetailPeopleClick(row) {
            if (row.group && row.group.length > 0) {
                let peopleDetailArray = []
                row.group.forEach((item, index) => {
                    let newarr = item.accessTimeArray.split("、");
                    newarr.pop();
                    let testArr = newarr.map(item1 => {
                        let [groupName,day, times] = item1.split(" ");
                        return {
                            name: day,
                            timeText: times,
                        };
                    });
                    let groupName = item.groupName;
                    peopleDetailArray.push(this.peopleMergeTimeData(testArr,groupName));
                })
                
                let flattDeepArr = this._.flattenDeep(peopleDetailArray);
                
                this.detail_people_table_data = flattDeepArr;
                this.spanArrOne = this.getSpanArr(this.detail_people_table_data, 'groupName')

            }
            
            
            this.detailPeopleDialog.dialogVisible = true;
        },
        /**
         * @Description: 时间设置详情弹窗的关闭
         * @Author: 闫乔
         * @Date: 2024-10-09 17:31:53
         */        
         handleDetailPeopleClose() {
            this.detailPeopleDialog.dialogVisible = false;
        },
        /**
         * @Description: 合并行处理规则
         * @Author: 闫乔
         * @Date: 2024-10-14 09:22:13
         * @param {*} row
         * @param {*} column
         * @param {*} rowIndex
         * @param {*} columnIndex
         */        
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.spanArrOne[rowIndex]
                const _col = _row > 0 ? 1 : 0
                return { rowspan: _row, colspan: _col }
            }
        },
        // 合并处理
        getSpanArr(data, params) {
            let arr = [] // 接收重构数组
            let spanArr = [] // 控制合并的数组
            let pos = 0 // 设置索引
            // 排序
            const list = this.groupBy(data, params)
            list.map(v => (arr = arr.concat(v)))
            arr.map(res => {
                data.shift()
                data.push(res)
            })
            const redata = arr.map(v => v[params])
            redata.reduce((old, cur, i) => {
                if (cur === old) {
                    spanArr[pos] += 1
                    spanArr.push(0)
                } else {
                    spanArr.push(1)
                    pos = i
                }
                return cur
            }, {})

            return spanArr
        },
        groupBy(data, params) {
            // 根据某个字段进行排序 输出二维数组
            const groups = {}
            data.forEach(row => {
                const group = row[params]
                groups[group] = groups[group] || []
                groups[group].push(row)
            })
            return Object.values(groups)
        },
        /**
         * @Description: 删除 人员出入组信息
         * @Author: 闫乔
         * @Date: 2024-10-10 15:01:12
         * @param {*} data
         */        
        delPeople(data) {
            this.delPersonObj = data;
            this.delId = data.personId;
            this.delPeopleDialog.dialogVisible = true;
        },
        /**
         * @Description: 处理判断数组内的type是否都是organ
         * @Author: 闫乔
         * @Date: 2024-10-12 14:11:59
         * @param {*} array
         */        
        allItemsAreOrgan(array) {
            return array.every(item => item.type === 'organ');
        },
        /**
         * @Description: 删除人员的出入组的确定
         * @Author: 闫乔
         * @Date: 2024-10-10 15:10:44
         */   
        handleDelPeopleSubmit() {
            let isAllOrgan = this.allItemsAreOrgan(this.delPersonObj.group);
            if (isAllOrgan) {
                // 所有出入组都是按部门
                this.$message.warning('删除失败，该人员的所有出入组都是按部门添加，无法删除');
                this.handleDelPeopleClose();
                return
            } else {
                // 所有出入组不都是按部门
                let campusSafety = new CampusSafetyModel();
                campusSafety
                    .deleteAccessPerson({
                        schoolId: this.schoolId,
                        psersonId : this.delId,
                        userPopulation: this.delPersonObj.userPopulation,
                    })
                    .then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success("删除成功");
                            this.handleDelPeopleClose();
                            this.getPeopleList();
                        });
                    });
            }
        },
        /**
         * @Description: 删除人员的出入组弹窗的取消
         * @Author: 闫乔
         * @Date: 2024-10-10 15:12:17
         */        
        handleDelPeopleClose() {
            this.delPeopleDialog.dialogVisible = false;
            this.delId = '';
            this.delPersonObj = {};
        },
        /**
         * @Description: 编辑人员的出入组的确定
         * @Author: 闫乔
         * @Date: 2024-10-10 15:10:44
         */   
        handleEditPeopleSubmit() {
            // 没有勾选不再提示
            if (!this.noMorePromptsChecked) {
                this.handleEditPeopleClose();
                this.editPeopleOpen(this.peopleEditObj);
            } else {
                // 勾选了不再提示
                let campusSafety = new CampusSafetyModel();
                campusSafety
                    .setAccessGroupDoNotPrompt({
                        schoolId: this.schoolId,
                        userId: this.userId,
                    })
                    .then((res) => {
                        this.handleRes(res, () => {
                            this.handleEditPeopleClose();
                            this.editPeopleOpen(this.peopleEditObj);
                        });
                    });
            }
        },
        /**
         * @Description: 编辑人员的出入组弹窗的取消
         * @Author: 闫乔
         * @Date: 2024-10-10 15:12:17
         */        
        handleEditPeopleClose() {
            this.editPeopleDialog.dialogVisible = false;
        },
        /**
         * @Description: 编辑弹窗的不再提示勾选
         * @Author: 闫乔
         * @Date: 2024-10-10 16:02:51
         * @param {*} data
         */        
        changeNoMorePrompts(data) {
            console.log("data是否勾选", data);
            
        },
        /**
         * @Description: 获取人员的编辑是否是不再提示
         * @Author: 闫乔
         * @Date: 2024-10-14 10:17:25
         */        
        async getPeopleDoNotPrompt() {
            let campusSafety = new CampusSafetyModel();
            await campusSafety
                .getAccessGroupDoNotPrompt({
                    schoolId: this.schoolId,
                    userId: this.userId,
                })
                .then((res) => {
                    this.handleRes(res, () => {
                        this.noMorePromptsChecked = res.data.data;
                    });
                });
        },
        /**
         * @Description: 人员出入组的编辑
         * @Author: 闫乔
         * @Date: 2024-10-10 16:16:15
         */        
        async editPeople(data) {
            await this.getPeopleDoNotPrompt();
            this.currentPeopleGroupName = '';
            this.currentPeopleGroupId = '';
            this.peopleEditObj = {};
            this.isPeopleEdit = true;
            this.isDidiableGroup = false;
            // 获取人员所在的组
            this.groupList = data.group;
            this.currentPeopleGroupName = this.groupList[0].groupName;
            this.currentPeopleGroupId = this.groupList[0].groupId;
            this.getCurrentGroupIsEdit(this.groupList[0]);
            this.peopleEditObj = data;
            
            // 用户点击了不再提示
            if (this.noMorePromptsChecked) {
                this.editPeopleOpen(data);
            } else {
                this.editPeopleDialog.dialogVisible = true;
            }
        },
        /**
         * @Description: 编辑 人员出入组信息
         * @Author: 闫乔
         * @Date: 2024-10-10 15:00:52
         * @param {*} data
         */        
        editPeopleOpen(data) {
            this.editLoading = true;
            this.peopleSelectedNum = 0;
            this.$emit("changeShowTab", false);
            this.$eventDispatch("setGlobalPageType", "form");
            console.log('编辑的数据',data);
            
            this.getEditInfo(data);
            this.showAddEdit = true;
        },
        /**
         * @Description: 处理编辑人员信息的回显
         * @Author: 闫乔
         * @Date: 2024-10-12 10:16:08
         */        
        getEditInfo(data) {
            let currentGroupId = this.currentPeopleGroupId; // 当前人所在的组
            this._fet(`/accessSchool/schoolAccessGroup/info/${currentGroupId}`).then(
                (res) => {
                    this.handleRes(res, () => {
                        Object.keys(this.ruleForm).forEach((key) => {
                            this.ruleForm[key] = res.data.data[key];
                        });
                        // 判断编辑的使用人员群是员工还是学生
                        this.ruleForm.groupTimeTypeName = ''
                        let typeList = {
                            0: "允许时间",
                            1: "禁止时间",
                        }
                        if (data.userPopulation == 1) {
                            this.ruleForm.groupTimeTypeName = typeList[data.teacherTimeType]
                        } else if (data.userPopulation == 2) {
                            this.ruleForm.groupTimeTypeName = typeList[data.studentTimeType]
                        }
                        this.ruleForm.groupName = this.currentPeopleGroupName; // 当前人所在的组
                        // 处理当前人员的信息
                        let currentPersonInfo = [{
                            bussinessId: data.personId,
                            name: data.personName,
                            type: data.userPopulation == '1' ? 'teacher' : 'student',
                            unionId: `${data.userPopulation == '1' ? 'teacher' : 'student'}_${data.personId}`
                        }]
                        this.ruleForm.organInfo = currentPersonInfo;
                        this.ruleForm.time = res.data.data.accessTimeInfo;
                        // 处理出入时间回显
                        if (res.data.data.accessTimeInfo && res.data.data.accessTimeInfo.length > 0) {
                            let timeArr = this.groupArrayPairs(
                                res.data.data.accessTimeInfo,
                            );
                            this.pushArr = timeArr.map((iv, id) => {
                                return {
                                    time: iv,
                                    isShowTip: false,
                                };
                            });
                        }

                        this.ruleForm.showName = data.personName;
                        
                        this.editLoading = false;
                        if (res.data.data.accessRule === "1") {
                            // 每周
                            for (let item of res.data.data.ruleInfo) {
                                let timeRangeArr = [];
                                for (const key of Object.keys(item)) {
                                    if (key.startsWith("startTime")) {
                                        const index = parseInt(
                                            key.replace("startTime", ""),
                                        );
                                        if (String(index) && String(index) != "NaN") {
                                            timeRangeArr.push(
                                                item[key],
                                                item[`endTime${index}`],
                                            );
                                        } else {
                                            timeRangeArr.push(item[key], item[`endTime`]);
                                        }
                                    }
                                }
                                let monthTimeArr = this.groupArrayPairs(timeRangeArr);
                                for (let weekitem of this.weekDateList) {
                                    let find = res.data.data.ruleInfo.find(
                                        (it) => weekitem.prop.slice(-1) === it.date.slice(-1),
                                    );
                                    if (find) {
                                        let eachWeekTime = monthTimeArr.map((iv, id) => {
                                            return {
                                                time: iv,
                                                isShowWeekTip: false,
                                            };
                                        });
                                        weekitem.sel = true;
                                        weekitem.value = eachWeekTime;
                                    } else {
                                        weekitem.sel = false;
                                        let eachWeekNotTime = monthTimeArr.map((iv, id) => {
                                            return {
                                                time: "",
                                                isShowWeekTip: false,
                                            };
                                        });
                                        weekitem.value = eachWeekNotTime;
                                    }
                                }
                            }
                        } else {                            
                            // 每月
                            let editNewMobthList = [];
                            for (let item of res.data.data.ruleInfo) {
                                let editMonthList = [];
                                let timeRangeArr = [];
                                for (const key of Object.keys(item)) {
                                    if (key.startsWith("startTime")) {
                                        const index = parseInt(
                                            key.replace("startTime", ""),
                                        );
                                        if (String(index) && String(index) != "NaN") {
                                            timeRangeArr.push(
                                                item[key],
                                                item[`endTime${index}`],
                                            );
                                        } else {
                                            timeRangeArr.push(item[key], item[`endTime`]);
                                        }
                                    }
                                }
                                let monthTimeArr = this.groupArrayPairs(timeRangeArr);
                                let eachMonthTime = monthTimeArr.map((iv, id) => {
                                    return {
                                        time: iv,
                                        isRepeatDate: false,
                                    };
                                });
                                editMonthList = {
                                    date: item.date,
                                    timeArr: eachMonthTime,
                                };
                                editNewMobthList.push(editMonthList);
                            }
                            this.newMonthDateList = editNewMobthList;
                        }                 
                    });
                },
            );
            this.whoCanInitiate(this.ruleForm.userPopulation);
            
        },
        /**
         * @Description: 切换组的选择，重新渲染数据
         * @Author: 闫乔
         * @Date: 2024-10-15 14:49:11
         * @param {*} e
         */        
        changeGroup(e) {
            this.$nextTick(() => {
                this.isDidiableGroup = false;
                let updateInfo = this.groupList.filter(item => item.groupId == e);
                this.currentPeopleGroupName = updateInfo[0].groupName;
                this.currentPeopleGroupId = updateInfo[0].groupId;
                this.getCurrentGroupIsEdit(updateInfo[0]);
                this.getEditInfo(this.peopleEditObj);
                
            });
        },
        /**
         * @Description: 查询当前选中组是否可编辑
         * @Author: 闫乔
         * @Date: 2024-10-14 11:05:11
         */        
        getCurrentGroupIsEdit(groupObj) {
            if (groupObj.type == 'organ') {
                this.isDidiableGroup = true;
            }
        },
        /**
         * @Description: 设置表格表头展示
         * @Author: 闫乔
         * @Date: 2024-10-09 11:30:03
         */        
        setPeopleTableHeader() {
            let userType = this.peopleFormData.data.filter(item => item.key == 'userPopulation')[0].value;
            if (userType == 1) { // 员工
                let typeConfig = {
                    label: "部门",
                    prop: "organName",
                    align: "center",
                    type: "popoverText",
                    type: "tooltipColumn",
                    splitSign: ",",
                    labelWidth: "200px",
                };
                this.peopleTable_config.thead.splice(2, 1, typeConfig);
            }
            if (userType == 2) { // 学生
                let typeConfig = {
                    label: "班级",
                    prop: "organName",
                    align: "center",
                    type: "popoverText",
                    type: "tooltipColumn",
                    splitSign: ",",
                    labelWidth: "200px",
                };
                this.peopleTable_config.thead.splice(2, 1, typeConfig);
                
            }
        },
        /**
         * @Description: 修改筛选人员类型
         * @Author: 闫乔
         * @Date: 2024-10-09 14:30:58
         * @param {*} data
         */        
        changeSel(data) {            
            // 修改统计范围
            if (data.key === "userPopulation") {
                let userType = this.peopleFormData.data.filter(item => item.key == 'userPopulation')[0].value;
                if (userType == 1) { // 员工
                    let selectFormData = {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "部门",
                        key: "organIdList",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            // children:"children",
                            multiple: true,
                            value: "id",
                            emitPath: false,
                            checkStrictly: true,
                        }
                    };
                    this.peopleFormData.data.splice(2, 1, selectFormData);
                    this.getOrgList();
                }
                if (userType == 2) { // 学生
                    let selectFormData = {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "organIdList",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        }
                    };
                    this.peopleFormData.data.splice(2, 1, selectFormData);
                    this.getOrg();
                }                
            }
        },
        /**
         * @Description: 获取学生班级下拉数据
         * @Author: 闫乔
         * @Date: 2024-10-09 11:31:07
         */        
        getOrg() {
            let obj = {
                onlyClassType1: 1,
                schoolId: this.schoolId,
            };
            this._fet(
                "/school/schoolOrgan/getAllGradeClassList",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    const treeData = listToTree(res.data.data, {
                        parentKey: "parentOrg",
                    });
                    let treeDataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    let jsonList = JSON.stringify(treeDataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.peopleFormData.data.forEach((item) => {
                        let keyAboutClassArr = ["organIdList"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 获取部门筛选下拉数据
         * @Author: 闫乔
         * @Date: 2024-10-09 14:17:52
         */        
        getOrgList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const list = res.data.data.list;
                    const list1 = list.filter((it) => it.organType == 1);
                    
                    let deptOptions = listToTree(list1, {
                        parentKey: "parentOrg",
                    });                    
                    let jsonList = JSON.stringify(deptOptions);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.peopleFormData.data.forEach((item) => {
                        if (["organIdList"].includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 覆写mixin计算表格高度
         * @Author: 闫乔
         * @Date: 2024-10-09 10:52:54
         */        
        changeHandler() {
            this.$nextTick(() => {
                if (this.$refs.table) {
                    let height = document.body.clientHeight - this.$refs.table.$el.offsetTop
                    if(document.getElementsByClassName('pagination-container')[0]) {
                        height -= this.excessHeight
                    } else {
                        height -= this.excessHeight2
                    }
                    if (this.table_config) {
                        this.table_config.height = height
                    } else if (this.tableConfig) {
                        this.tableConfig.height = height
                    }
                }
                if (this.$refs.peopleTable) {
                    let height = document.body.clientHeight - this.$refs.peopleTable.$el.offsetTop
                    if(document.getElementsByClassName('pagination-container')[0]) {
                        height -= this.excessHeight
                    } else {
                        height -= this.excessHeight2
                    }
                    if (this.peopleTable_config) {
                        this.peopleTable_config.height = height
                    }
                }
            });
        },
        /**
         * @Description: 获取时间设置
         * @Author: 闫乔
         * @Date: 2024-10-09 15:02:26
         */        
        async getGroupTimeList() {
            let campusSafety = new CampusSafetyModel();
            await campusSafety.getGroupTimeType({schoolId: this.schoolId}).then((res) => {
                this.handleRes(res, () => {
                    this.studentType = res.data.data.student;
                    this.studentCount = res.data.data.studentCount;
                    this.teacherType = res.data.data.teacher;
                    this.teacherCount = res.data.data.teacherCount;
                })
            });
        },
        getList(t) {
            this.loadingTable = true;
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            let paramsQuery = {};
            if (this.checked) {
                paramsQuery = Object.assign({}, this.listQuery,{ personNum: 1 })
            } else {
                paramsQuery = this.listQuery
            }
            let campusSafety = new CampusSafetyModel();
            campusSafety.getAccessGroupList(paramsQuery).then((res) => {
                this.handleRes(res, () => {
                    for (let item of res.data.data.list) {
                        // item.accessTime = "";
                        item.detailAccessTime = "";
                        for (let item2 of item.ruleInfo) {
                            let timeRangeArr = [];
                            for (const key of Object.keys(item2)) {
                                if (key.startsWith("startTime")) {
                                    const index = parseInt(
                                        key.replace("startTime", ""),
                                    );
                                    if (
                                        String(index) &&
                                        String(index) != "NaN"
                                    ) {
                                        timeRangeArr.push({
                                            startTime: item2[key],
                                            endTime: item2[`endTime${index}`],
                                        });
                                    } else {
                                        timeRangeArr.push({
                                            startTime: item2[key],
                                            endTime: item2[`endTime`],
                                        });
                                    }
                                }
                            }
                            timeRangeArr.forEach((items, indexs) => {
                                // item.accessTime +=
                                //     items.startTime +
                                //     "-" +
                                //     items.endTime +
                                //     "、";
                                item.detailAccessTime +=
                                    item2.date +
                                    " " +
                                    items.startTime +
                                    "-" +
                                    items.endTime +
                                    "、";
                            });
                        }
                    }
                    res.data.data.list.forEach((item) => {
                        item.studentTimeType = this.studentType;
                        item.teacherTimeType = this.teacherType;
                    })
                    this.table_data = res.data.data.list;
                    this.table_data
                    this.total = res.data.data.totalCount;
                    this.tableKey = this.tableKey + 1;
                    this.loadingTable = false;
                });
            });
        },
        // 获取人员信息
        async getTreeData(type) {
            // 第一次获取数据
            if (type === "init") {
                this.selTreeOptList = [];
                this.treeShapeInitLoading = true;
            }
            this.treeData.scope = this.ruleForm.scope;
            const commonModel = new CommonModel();
            await commonModel.getAddressBookList(this.treeData).then((res) => {
                if (res.data.code == "200") {
                    this.selTreeOptList = res.data.data;
                    // 第一次获取数据
                    if (type === "init") {
                        this.treeShapeInitLoading = false;
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        changeScoped(data) {
            // 判断编辑的使用人员群是员工还是学生
            this.ruleForm.groupTimeTypeName = ''
            let typeList = {
                0: "允许时间",
                1: "禁止时间",
            }            
            if (data == 1) {
                this.ruleForm.groupTimeTypeName = typeList[this.teacherType]
            } else if (data == 2) {
                this.ruleForm.groupTimeTypeName = typeList[this.studentType]
            }
            this.groupTimeTypeNameKey += 1;
            this.dialogObj.title =
                data == 1 ? "选择部门/教职工" : "选择年级/班级/学生";
            this.ruleForm.showName = "";
            this.whoCanInitiate(data);
            this.selectObjData = [];
            this.ruleForm.organInfo = [];
            this.peopleSelectedNum = 0;
            // this.getTreeData();
        },
        // 学生类型修改
        handleScopeChange() {
            this.ruleForm.showName = "";
            this.selectObjData = [];
            this.ruleForm.organInfo = [];
            this.peopleSelectedNum = 0;
        },

        /**
         * @Description: 点击添加
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-14 15:49:14
         */
        addTimeGroup: debounce(function () {
            if (this.pushArr && this.pushArr.length == 5) {
                return;
            }
            this.pushArr.push({
                time: "",
            });
            this.weekDateList.forEach((item) => {
                item.value.push({
                    time: "",
                    isShowWeekTip: false,
                });
            });
        }, 200),
        /**
         * @Description: 删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-14 15:49:59
         */
        handleDelete: debounce(function (param) {
            const index = param;
            this.pushArr.splice(index, 1);
            this.weekDateList.forEach((item) => {
                item.value.splice(index, 1);
            });
            console.log(
                "this.newMonthDateList删除时间段",
                this.newMonthDateList,
            );
            // 自定义日历
            if (this.newMonthDateList && this.newMonthDateList.length > 0) {
                this.newMonthDateList.forEach((ivs1, ids1) => {
                    ivs1.timeArr.splice(index, 1);
                });
            }
            this.calendarKey += 1;
        }, 200),
        /**
         * @Description: 切换出入规则
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-19 15:49:59
         */
        changeAccessRule() {
            console.log(
                "this.newMonthDateList切换出入规则后的",
                this.newMonthDateList,
            );
        },
        // 显示树形弹窗组件
        async showTreeDialog() {
            this.treeData.parentId = "";
            this.getTreeData("init");

            if (this.ruleForm.id) {
                this.ruleForm.organInfo.forEach((item) => {
                    item.id = item.bussinessId || item.id;
                });

                this.selectObjData = this.ruleForm.organInfo;
            }

            console.log("this.selectObjData", this.selectObjData);
            this.dialogObj.key += 1;
            this.dialogObj.dialogVisible = true;
        },
        // 关闭树形弹窗组件
        closeTreeDialog() {
            this.dialogObj.dialogVisible = false;
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
        },
        // 星期checkbox change事件
        changeWeekSel(index) {
            this.weekDateList[index].sel = !this.weekDateList[index].sel;
        },
        // 选中
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        /**
         * @Description: 导出列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-17 15:18:09
         */
        exportList() {
            this.exportListLoading = true;
            this.exportBtnLoading = true;
            let obj = {
                schoolId: this.schoolId,
            };
            // 表格选中数据
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map((i) => i.id);
                obj.identification = 2;
            } else {
                let filter = this.formData.data.filter((i) => i.value !== "");
                // 未选中筛选条件，导出全部
                if (filter.length === 0) {
                    obj.identification = 3;
                } else {
                    obj.identification = 1;
                    // 选中筛选
                    filter.forEach((item) => {
                        obj[item.key] = item.value;
                    });
                }
            }
            downloadFile(
                {url: "/accessSchool/schoolAccessGroup/export", form: obj},
                () => {
                    this.$message.success("导出成功");
                    this.exportListLoading = false;
                    this.exportBtnLoading = false;
                },
                () => {
                    this.exportListLoading = false;
                    this.exportBtnLoading = false;
                },
            );
        },
        /**
         * @Description: 导出全部
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-17 15:18:09
         */
        exportAll() {
            this.exportAllLoading = true;
            this.exportBtnLoading = true;
            let obj = {
                schoolId: this.schoolId,
            };
            // 表格选中数据
            if (this.tableListSel.length > 0) {
                obj.ids = this.tableListSel.map((i) => i.id);
                obj.identification = 2;
            } else {
                let filter = this.formData.data.filter((i) => i.value !== "");
                // 未选中筛选条件，导出全部
                if (filter.length === 0) {
                    obj.identification = 3;
                } else {
                    obj.identification = 1;
                    // 选中筛选
                    filter.forEach((item) => {
                        obj[item.key] = item.value;
                    });
                }
            }
            downloadFile(
                {url: "/accessSchool/schoolAccessGroup/exportAll", form: obj},
                () => {
                    this.$message.success("导出成功");
                    this.exportAllLoading = false;
                    this.exportBtnLoading = false;
                },
                () => {
                    this.exportAllLoading = false;
                    this.exportBtnLoading = false;
                },
            );
        },
        // 多选修改启用状态
        multiChangeStatus(status) {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("请勾选数据后操作！");
            }
            if (status === 1) {
                this.$confirm("此操作将批量更改为启用状态, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        let data = this.tableListSel.map((i) => {
                            return {id: i.id, status: status};
                        });
                        this.changeStatus(data);
                    })
                    .catch(() => {});
            } else {
                this.$confirm("此操作将批量更改为禁用状态, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        let data = this.tableListSel.map((i) => {
                            return {id: i.id, status: status};
                        });
                        this.changeStatus(data);
                    })
                    .catch(() => {});
            }
        },
        // 修改启用状态
        changeStatus(data) {
            let campusSafety = new CampusSafetyModel();
            campusSafety
                .isAbleAccessGroup({
                    list: data,
                })
                .then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success("操作成功");
                        this.getList();
                        this.tableListSel = [];
                    });
                });
        },
        // 添加编辑返回
        back() {
            this.showAddEdit = false;
            this.$emit("changeShowTab", true);
        },
        /**
         * @Description: 处理数组为两两一组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-21 15:18:09
         * @param {*} data
         */
        groupArrayPairs(arr) {
            const result = [];
            for (let i = 0; i < arr.length; i += 2) {
                result.push([arr[i], arr[i + 1]]);
            }
            return result;
        },
        /**
         * @Description: 编辑 -> 组维度的编辑
         * @Author: 闫乔
         * @Date: 2024-10-11 10:03:03
         * @param {*} data
         */        
        edit(data) {
            this.editLoading = true;
            this.isPeopleEdit = false;
            this.isDidiableGroup = false;
            this.peopleSelectedNum = 0;
            this.$emit("changeShowTab", false);
            this.$eventDispatch("setGlobalPageType", "form");
            console.log("this.treeData", this.treeData);
            console.log('编辑的数据',data);
            
            this._fet(`/accessSchool/schoolAccessGroup/info/${data.id}`).then(
                (res) => {
                    console.log("[编辑]接口返回：", res);
                    this.handleRes(res, () => {
                        this.ruleForm.organInfo = res.data.data.organList;
                        this.ruleForm.time = res.data.data.accessTimeInfo;
                        // 处理出入时间回显
                        if (res.data.data.accessTimeInfo && res.data.data.accessTimeInfo.length > 0) {
                            let timeArr = this.groupArrayPairs(
                                res.data.data.accessTimeInfo,
                            );
                            this.pushArr = timeArr.map((iv, id) => {
                                return {
                                    time: iv,
                                    isShowTip: false,
                                };
                            });
                        }

                        console.log("this.pushArr", this.pushArr);
                        this.selectObjData = this._.cloneDeep(
                            this.ruleForm.organInfo,
                        );
                        const allOrganIds = this.selectObjData.map((i) => i.id);
                        let organNames = this.selectObjData.filter(
                            (i) => !allOrganIds.includes(i.parentOrg),
                        );
                        this.ruleForm.showName = [...organNames]
                            .map((i) => i.name)
                            .toString();
                        this.ruleForm.scope = res.data.data.scope
                            ? res.data.data.scope
                            : "3";
                        for (let item of this.selectObjData) {
                            item.id = item.bussinessId;
                            item.scope = this.ruleForm.scope;
                        }
                        this.$set(this.ruleForm, "stuPick", res.data.data.stuPick ? res.data.data.stuPick : "2")
                        this.getPeopleSelectedNum();
                        this.editLoading = false;
                    });
                },
            );
            if (data.accessRule === "1") {
                // 每周
                for (let item of data.ruleInfo) {
                    let timeRangeArr = [];
                    for (const key of Object.keys(item)) {
                        if (key.startsWith("startTime")) {
                            const index = parseInt(
                                key.replace("startTime", ""),
                            );
                            if (String(index) && String(index) != "NaN") {
                                timeRangeArr.push(
                                    item[key],
                                    item[`endTime${index}`],
                                );
                            } else {
                                timeRangeArr.push(item[key], item[`endTime`]);
                            }
                        }
                    }
                    let monthTimeArr = this.groupArrayPairs(timeRangeArr);
                    for (let weekitem of this.weekDateList) {
                        let find = data.ruleInfo.find(
                            (it) => weekitem.prop.slice(-1) === it.date.slice(-1),
                        );
                        if (find) {
                            let eachWeekTime = monthTimeArr.map((iv, id) => {
                                return {
                                    time: iv,
                                    isShowWeekTip: false,
                                };
                            });
                            weekitem.sel = true;
                            weekitem.value = eachWeekTime;
                        } else {
                            weekitem.sel = false;
                            let eachWeekNotTime = monthTimeArr.map((iv, id) => {
                                return {
                                    time: "",
                                    isShowWeekTip: false,
                                };
                            });
                            weekitem.value = eachWeekNotTime;
                        }
                    }
                }
            } else {
                // 每月
                let editNewMobthList = [];
                for (let item of data.ruleInfo) {
                    let editMonthList = [];
                    let timeRangeArr = [];
                    for (const key of Object.keys(item)) {
                        if (key.startsWith("startTime")) {
                            const index = parseInt(
                                key.replace("startTime", ""),
                            );
                            if (String(index) && String(index) != "NaN") {
                                timeRangeArr.push(
                                    item[key],
                                    item[`endTime${index}`],
                                );
                            } else {
                                timeRangeArr.push(item[key], item[`endTime`]);
                            }
                        }
                    }
                    let monthTimeArr = this.groupArrayPairs(timeRangeArr);
                    let eachMonthTime = monthTimeArr.map((iv, id) => {
                        return {
                            time: iv,
                            isRepeatDate: false,
                        };
                    });
                    editMonthList = {
                        date: item.date,
                        timeArr: eachMonthTime,
                    };
                    editNewMobthList.push(editMonthList);
                }
                this.newMonthDateList = editNewMobthList;
            }
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = data[key];
            });
            // 判断编辑的使用人员群是员工还是学生
            this.ruleForm.groupTimeTypeName = ''
            let typeList = {
                0: "允许时间",
                1: "禁止时间",
            }
            if (data.userPopulation == 1) {
                
                this.ruleForm.groupTimeTypeName = typeList[data.teacherTimeType]
            } else if (data.userPopulation == 2) {
                this.ruleForm.groupTimeTypeName = typeList[data.studentTimeType]
            }
            this.whoCanInitiate(this.ruleForm.userPopulation);
            this.showAddEdit = true;
        },
        // 批量删除
        multiDel() {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("请勾选数据后操作！");
            }
            let ids = this.tableListSel.map((i) => ({id: i.id}));
            this.del(ids);
        },
        // 删除
        del(data) {
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let campusSafety = new CampusSafetyModel();
                    campusSafety
                        .deleteAccessGroup({
                            list: data,
                        })
                        .then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success("删除成功");
                                this.getList();
                            });
                        });
                })
                .catch(() => {});
        },
        /**
         * @Description: 复制
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-12 15:18:09
         * @param {*} data
         */
        copy(data) {
            this.$confirm("是否确认复制当前表单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet(
                        `/accessSchool/schoolAccessGroup/copyAccessGroup/${data}`,
                    ).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success("复制成功");
                            this.getList();
                        });
                    });
                })
                .catch(() => {});
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getList(1);
                    break;
            }
        },
        /**
         * @Description: 人员维度的列表查询
         * @Author: 闫乔
         * @Date: 2024-10-12 09:34:30
         * @param {*} ev
         */        
        peopleClickBtn(ev) {
            switch (ev.item.fn) {
                case "primary": // 查询
                    this.peopleFormData.data.forEach((item) => {
                        this.peopleListQuery[item.key] = item.value;
                    });
                    this.getPeopleList(1);
                    break;
            }
        },
        handleAdd() {
            this.editLoading = false;
            this.isPeopleEdit = false;
            this.peopleSelectedNum = 0;
            this.isDidiableGroup = false;
            this.showAddEdit = true;
            this.$emit("changeShowTab", false);
            this.$eventDispatch("setGlobalPageType", "form");
            this.whoCanInitiate("1");
            // 判断编辑的使用人员群是员工还是学生
            this.ruleForm.groupTimeTypeName = ''
            let typeList = {
                0: "允许时间",
                1: "禁止时间",
            }
            this.ruleForm.groupTimeTypeName = typeList[this.teacherType]
            
        },
        /**
         * @Description: 修改出入规则的时间
         * @DoWhat: 周
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-17 15:18:09
         * @param {*} data
         */
        changeWeekTime(item, index, weekIndex) {
            // 出入规则
            // 选择时间段，与已选择的时间段不能重复
            let weekTimeList = this.weekDateList[weekIndex].value;
            this.handleWeekTimeOverlap(weekTimeList, weekIndex, index);
        },
        /**
         * @Description: 修改出入规则的时间
         * @DoWhat: 自定义
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-20 10:18:09
         * @param {*} data
         */
        changeMonthTime(item, index, data) {
            console.log("data出入规则日历的时间", item, index, data);
            let monthDateIndex = this.newMonthDateList.findIndex((item) => {
                return item.date == data.date;
            });
            // 赋值，修改日历选择的时间段
            this.newMonthDateList[monthDateIndex].timeArr[index] = item;
            // 出入规则 自定义
            // 选择时间段，与已选择的时间段不能重复
            // this.newMonthDateList.forEach((eachItem, eachIndex) => {
                let curTimeList = this.newMonthDateList[monthDateIndex].timeArr;
                console.log("curTimeList", curTimeList);
                this.handleMonthTimeOverlap(curTimeList, index, monthDateIndex);
            // });
        },

        changeMonthTime1(item, index, data) {
            console.log("data出入规则日历的时间", item, index, data);
            let monthDateIndex = this.newMonthDateList.findIndex((item) => {
                return item.date == data.date;
            });
            console.log("monthDateIndex", monthDateIndex);
            // 赋值，修改日历选择的时间段
            this.newMonthDateList[monthDateIndex].timeArr[0].time = item;
            data.timeRangeArr[0].time = item;
            console.log("this.newMonthDateList", this.newMonthDateList);
            // 出入规则 自定义
            // 选择时间段，与已选择的时间段不能重复
            let curTimeList = this.newMonthDateList[monthDateIndex].timeArr;
            console.log("curTimeList判重的时间列", curTimeList);
            this.handleMonthTimeOverlap1(curTimeList, index, monthDateIndex);
            this.calendarKey += 1;
        },
        /**
         * @Description: 校验出入规则自定义选择的时间段是否重叠
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-20 15:18:09
         * @param {*} data
         */
        handleMonthTimeOverlap(arr, index, monthDateIndex) {
            let dayAndTimeArr = [];
            console.log("arr", arr);
            arr.forEach((item) => {
                console.log("item", item);
                if (item.time) {
                    item.timesRangeArr = item.time.map((timeItem) => {
                        if (timeItem && timeItem.length > 0) {
                            return timeItem;
                        }
                    });
                    if (item.timesRangeArr && item.timesRangeArr.length > 0) {
                        dayAndTimeArr.push(
                            ...this.handelDataFn(item.timesRangeArr),
                        );
                    }
                }
            });
            let isRepeat = this.checkTimeRangesOverlap(dayAndTimeArr);
            if (isRepeat) {
                console.log("重叠");
                let monthDateTimeObj = this.newMonthDateList[monthDateIndex];
                if (monthDateTimeObj) {
                    if (
                        monthDateTimeObj.timeArr &&
                        monthDateTimeObj.timeArr[index]
                    ) {
                        console.log('monthDateTimeObj.timeArr[index]',monthDateTimeObj.timeArr);
                        // this.$set(
                        //     monthDateTimeObj.timeArr[index],
                        //     "isRepeatDate",
                        //     true,
                        // );
                        let rangeArrList = [];
                        monthDateTimeObj.timeArr.forEach((im,imIndex) => {
                            if (Boolean(im.time)) {
                                let imObj = {
                                    oldInd:imIndex,
                                    startTime: "",
                                    endTime: "",
                                    id: `${imIndex}_`
                                }
                                if (im.time && im.time.length > 0) {
                                    imObj.startTime = im.time[0].split(":").join("");
                                    imObj.endTime = im.time[1].split(":").join("");
                                }
                                rangeArrList.push(imObj)
                            }
                        })
                        console.log('rangeArrList处理之后的',rangeArrList);

                        rangeArrList.forEach((rangeItem,rangeIndex) => {
                            let hasRepeatMonth = this.getWeekItemIsRepeat(rangeItem,rangeArrList);
                            if (hasRepeatMonth) {
                                this.$set(
                                    this.newMonthDateList[monthDateIndex].timeArr[rangeItem.oldInd],
                                    "isRepeatDate",
                                    true,
                                );
                                this.calendarKey += 1;
                            }  else {
                                this.$set(
                                    this.newMonthDateList[monthDateIndex].timeArr[rangeItem.oldInd],
                                    "isRepeatDate",
                                    false,
                                );
                            }
                        })
                        console.log('this.newMonthDateList++++++++++++++++',this.newMonthDateList);
                    }
                } else {
                }
            } else {
                console.log("没重复");
                let monthDateTimeObj = this.newMonthDateList[monthDateIndex];
                if (monthDateTimeObj) {
                    if (
                        monthDateTimeObj.timeArr &&
                        monthDateTimeObj.timeArr[index]
                    ) {
                        monthDateTimeObj.timeArr.map((i) => {
                            i.isRepeatDate = false;
                        });
                        // monthDateTimeObj.timeArr[index].isRepeatDate = false;
                    }
                }
            }
        },
        handleMonthTimeOverlap1(arr, index, monthDateIndex) {
            let dayAndTimeArr = [];
            console.log("arr", arr);
            arr.forEach((item) => {
                console.log("item", item);
                if (item.time) {
                    item.timesRangeArr = item.time.map((timeItem) => {
                        if (timeItem && timeItem.length > 0) {
                            return timeItem;
                        }
                    });
                    if (item.timesRangeArr && item.timesRangeArr.length > 0) {
                        dayAndTimeArr.push(
                            ...this.handelDataFn(item.timesRangeArr),
                        );
                    }
                }
            });
            let isRepeat = this.checkTimeRangesOverlap(dayAndTimeArr);
            if (isRepeat) {
                console.log("重叠");
                let monthDateTimeObj = this.newMonthDateList[monthDateIndex];
                console.log(
                    "monthDateTimeObj++++++++++++++",
                    monthDateTimeObj,
                    index,
                    monthDateTimeObj.timeArr[0],
                );
                if (monthDateTimeObj) {
                    if (
                        monthDateTimeObj.timeArr &&
                        monthDateTimeObj.timeArr[0]
                    ) {
                        // monthDateTimeObj.timeArr[0].isRepeatDate = true;
                        let rangeArrList = [];
                        monthDateTimeObj.timeArr.forEach((im,imIndex) => {
                            if (Boolean(im.time)) {
                                let imObj = {
                                    oldInd:imIndex,
                                    startTime: "",
                                    endTime: "",
                                    id: `${imIndex}_`
                                }
                                if (im.time && im.time.length > 0) {
                                    imObj.startTime = im.time[0].split(":").join("");
                                    imObj.endTime = im.time[1].split(":").join("");
                                }
                                rangeArrList.push(imObj)
                            }
                        })
                        console.log('rangeArrList处理之后的',rangeArrList);

                        rangeArrList.forEach((rangeItem,rangeIndex) => {
                            let hasRepeatMonth = this.getWeekItemIsRepeat(rangeItem,rangeArrList);
                            console.log('hasRepeatMonthPPPPPPPPPPPPPPPPPPPP',hasRepeatMonth);
                            if (hasRepeatMonth) {
                                this.$set(
                                    this.newMonthDateList[monthDateIndex].timeArr[rangeItem.oldInd],
                                    "isRepeatDate",
                                    true,
                                );
                                this.calendarKey += 1;
                            }  else {
                                this.$set(
                                    this.newMonthDateList[monthDateIndex].timeArr[rangeItem.oldInd],
                                    "isRepeatDate",
                                    false,
                                );
                            }
                        })
                    }
                    console.log("monthDateTimeObj", monthDateTimeObj);
                } else {
                }
            } else {
                console.log("没重复");
                let monthDateTimeObj = this.newMonthDateList[monthDateIndex];
                if (monthDateTimeObj) {
                    if (
                        monthDateTimeObj.timeArr &&
                        monthDateTimeObj.timeArr[0]
                    ) {
                        monthDateTimeObj.timeArr[0].isRepeatDate = false;
                    }
                }
            }
        },
        /**
         * @Description: 校验出入规则选择的时间段是否重叠
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-12 15:18:09
         * @param {*} data
         */
        handleWeekTimeOverlap(arr, index, ownIndex) {
            console.log("arr当前天的时间段数组", arr,'index改的第几个时间段',ownIndex,'星期几',index);
            let dayAndTimeArr = [];
            arr.forEach((item) => {
                if (item.time) {
                    item.timesRangeArr = item.time;
                    console.log("item.timesRangeArr", item.timesRangeArr);
                    if (item.timesRangeArr && item.timesRangeArr.length > 0) {
                        dayAndTimeArr.push(
                            ...this.handelDataFn(item.timesRangeArr),
                        );

                        let isRepeat =
                            this.checkTimeRangesOverlap(dayAndTimeArr);
                        console.log("出入规则的时间段是否重叠", isRepeat);
                        if (isRepeat) {
                            this.weekDateList.forEach((iv, id) => {
                                // 找到当前 天 的时间段
                                if (id == index) {
                                    let rangeArrList = [];
                                    console.log('iv.value',iv.value);
                                    iv.value.forEach((im,imIndex) => {
                                        if (Boolean(im.time)) {
                                            let imObj = {
                                                oldInd:imIndex,
                                                startTime: "",
                                                endTime: "",
                                                id: `${imIndex}_`
                                            }
                                            if (im.time && im.time.length > 0) {
                                                imObj.startTime = im.time[0].split(":").join("");
                                                imObj.endTime = im.time[1].split(":").join("");
                                            }
                                            rangeArrList.push(imObj)
                                        } else {

                                        }

                                    })
                                    console.log('rangeArrList处理之后的',rangeArrList);

                                    rangeArrList.forEach((rangeItem,rangeIndex) => {
                                        let hasRepeatWeek = this.getWeekItemIsRepeat(rangeItem,rangeArrList);
                                        if (hasRepeatWeek) {
                                            console.log('rangeItem.oldInd',rangeItem.oldInd);
                                            iv.value[rangeItem.oldInd].isShowWeekTip = true;
                                        }  else {
                                            iv.value[rangeItem.oldInd].isShowWeekTip = false;
                                        }
                                    })


                                }
                            });
                        } else {
                            this.weekDateList.forEach((iv, id) => {
                                iv.value.forEach((eachTime, eachIndex) => {
                                    eachTime.isShowWeekTip = false;
                                });
                            });
                        }
                    }
                }
            });
        },
        /**
         * @Description: 修改出入时间
         * @DoWhat: 校验选择的出入时间段是否重叠
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-12 15:18:09
         * @param {*} data
         */
        changeTime(data, index) {
            console.log("data出入时间", data, index);
            if (!data) {
                let obj = this.pushArr[index];
                this.$set(obj, "time", "");
                this.$set(obj, "timesRangeArr", []);
            }
            // 修改出入时间
            console.log("this.pushArr", this.pushArr);
            // 选择时间段，与已选择的时间段不能重复
            this.handleProcessTimeOverlap(this.pushArr, index);
        },
        /**
         * @Description: 处理选择时间段重复交叉的方法
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-12 15:18:09
         * @param {*} data
         */
        handleProcessTimeOverlap(obj, index) {
            let dayAndTimeArr = [];
            obj.forEach((item) => {
                if (item.time) {
                    item.timesRangeArr = item.time.map((time) => {
                        if (time && time.length > 0) {
                            return time;
                        }
                    });
                    console.log(
                        "item.timesRangeArr时间段",
                        item.timesRangeArr,
                        item.time,
                    );
                    if (item.timesRangeArr && item.timesRangeArr.length > 0) {
                        console.log();
                        dayAndTimeArr.push(
                            ...this.handelDataFn(item.timesRangeArr),
                        );
                        let isRepeat =
                            this.checkTimeRangesOverlap(dayAndTimeArr);
                        console.log("是否重叠", isRepeat);
                        if (isRepeat) {
                            this.pushArr.forEach((iv, id) => {
                                if (id == index) {
                                    iv.isShowTip = true;
                                } else {
                                    iv.isShowTip = false;
                                }
                            });
                            this.key += 1;
                            this.weekDateList.forEach((items, indexs) => {
                                let obj = items.value[index];
                                this.$set(obj, "time", "");
                            });
                            console.log(
                                "this.newMonthDateList重叠中日历数据",
                                this.newMonthDateList,
                            );
                            if (
                                this.newMonthDateList &&
                                this.newMonthDateList.length > 0
                            ) {
                                this.newMonthDateList.forEach((ivs1, ids1) => {
                                    console.log("ivs1", ivs1);
                                    let monthDateObj = ivs1.timeArr[index];
                                    if (monthDateObj) {
                                        this.$set(monthDateObj, "time", "");
                                    }
                                });
                            }
                            this.calendarKey += 1;
                        } else {
                            // 不重叠
                            this.pushArr.forEach((iv, id) => {
                                iv.isShowTip = false;
                            });
                            this.key += 1;
                            let timeArr = this.pushArr.map((iv) => {
                                return iv.timesRangeArr;
                            });
                            console.log('this.weekDateList',this.weekDateList);
                            // 出入规则
                            // 每周出入时间自动赋值
                            if (this.ruleForm.accessRule === "1") {
                                this.weekDateList.forEach((items, indexs) => {
                                    let obj = items.value[index];
                                    this.$set(obj, "time", timeArr[index]);
                                    let eachValues = items.value;
                                    // 判断是否为空
                                    if (eachValues) {
                                        eachValues.map((ivs, ids) => {
                                            if (ivs.time && ivs.time.length == 0) {
                                                if (
                                                    timeArr[ids] &&
                                                    timeArr[ids].length > 0 &&
                                                    !this.pushArr[ids].isShowTip
                                                ) {
                                                    this.$set(
                                                        items.value[ids],
                                                        "time",
                                                        timeArr[ids],
                                                    );
                                                }
                                            }
                                        });
                                    }
                                });
                            } else {
                                console.log(
                                    "this.newMonthDateList没有重叠的",
                                    this.newMonthDateList,
                                );
                                // 自定义日历
                                if (
                                    this.newMonthDateList &&
                                    this.newMonthDateList.length > 0
                                ) {
                                    this.newMonthDateList.forEach((ivs1, ids1) => {
                                        let obj1 = ivs1.timeArr[index];
                                        if (obj1) {
                                            this.$set(obj1, "time", timeArr[index]);
                                        } else {
                                            ivs1.timeArr.push({
                                                time: timeArr[index],
                                                isRepeatDate: false,
                                            });
                                        }
                                        let eachValues = ivs1.timeArr;
                                        // 判断是否为空
                                        if (eachValues) {
                                            eachValues.map((ivs, ids) => {
                                                if (
                                                    ivs.time &&
                                                    ivs.time.length == 0
                                                ) {
                                                    if (
                                                        timeArr[ids] &&
                                                        timeArr[ids].length > 0 &&
                                                        !this.pushArr[ids].isShowTip
                                                    ) {
                                                        this.$set(
                                                            ivs1.timeArr[ids],
                                                            "time",
                                                            timeArr[ids],
                                                        );
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                                this.calendarKey += 1;
                            }
                        }
                    }
                }
            });
        },
        /**
         * @Description: 处理时间段数据
         * @DoWhat: 去掉冒号，判断0000-2359
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-12 15:18:09
         * @param {*} data
         */
        handelDataFn(arrTime) {
            console.log(arrTime, "-=--=-=-");
            let arr = [];
            let query = {
                startTime: "",
                endTime: "",
            };
            if (arrTime && arrTime.length > 0) {
                query.startTime = arrTime[0].split(":").join("");
                query.endTime = arrTime[1].split(":").join("");
                arr.push(query);
            }
            return arr;
        },
        /**
         * @Description: 判断时间段重叠
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-12 15:18:09
         * @param {*} data
         */
        checkTimeRangesOverlap(ranges) {
            // 将时间段按照开始时间排序
            const sortedRanges = ranges
                .slice()
                .sort((a, b) => Number(a.startTime) - Number(b.startTime));

            // 检查时间段是否重叠
            for (let i = 1; i < sortedRanges.length; i++) {
                if (sortedRanges[i].startTime <= sortedRanges[i - 1].endTime) {
                    return true;
                }
            }

            // 所有时间段都没有重叠
            return false;
        },
        getWeekItemIsRepeat(curTime,timeAllArr){
            // timeAllArr为总时间数组的集合
            // curTime为单个时间组对象
            let noCurTimeArr = timeAllArr.filter(item => item.id != curTime.id)
            console.log('noCurTimeArr',noCurTimeArr);
            for (let i = 0; i < noCurTimeArr.length; i++) {
                let arr = [curTime,noCurTimeArr[i]];
                arr.sort((a, b) => Number(a.startTime) - Number(b.startTime));
                for (let j = 1; j < arr.length; j++) {
                    if (arr[j].startTime <= arr[j - 1].endTime) {
                        console.log('arr',arr[j],arr[j - 1]);
                        return true
                    }
                }
            }

            return false

        },
        // 日历点击
        calendarClick(data) {
            console.log("日历点击的data", data, data[1]);
            let dateObj = {
                date: data[1],
                timeArr: [],
            };
            console.log("this.pushAr", this.pushArr);
            let timesRangeArr = this.pushArr.map((iv) => {
                if (iv.time && iv.time.length > 0) {
                    if (iv.isShowTip) {
                        return "";
                    } else {
                        return iv.time;
                    }
                } else {
                    return "";
                }
            });
            console.log("timesRangeArr上面选择的时间段", timesRangeArr);
            if (timesRangeArr && timesRangeArr.length > 0) {
                timesRangeArr.forEach((item1, index1) => {
                    if (!item1) {
                        item1 = "";
                    }
                    let obj = {
                        time: item1,
                        isRepeatDate: false,
                    };
                    dateObj.timeArr.push(obj);
                });
                console.log("dateObj日历的", dateObj);
            } else {
                let obj1 = {
                    time: "",
                    isRepeatDate: false,
                };
                dateObj.timeArr.push(obj1);
            }
            let monthDateIndex = this.newMonthDateList.findIndex((item) => {
                return item.date == data[1];
            });
            console.log("当前点击的是第", monthDateIndex, "项");
            if (monthDateIndex < 0) {
                this.newMonthDateList.push(dateObj);
            } else {
                this.newMonthDateList.splice(monthDateIndex, 1);
            }
            console.log(
                "this.newMonthDateList处理过后的",
                this.newMonthDateList,
            );
        },
        /**
         * @Description: 去重
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-19 15:48:03
         */
        unique(arr, val) {
            const res = new Map();
            return arr.filter(
                (item) => !res.has(item[val]) && res.set(item[val], 1),
            );
        },
        // 新增编辑取消
        resetForm() {
            this.isPeopleEdit = false;
            this.$eventDispatch("setGlobalPageType", "list");
            this.$refs.ruleForm.clearValidate();
            if (this.$refs.selTree) {
                this.$refs.selTree.resetChecked();
            }
            // 每周选择出入时间
            this.weekDateList.forEach((item) => {
                item.sel = true;
                item.value = [
                    {
                        time: "",
                        isShowWeekTip: false,
                    },
                ];
            });
            // 每月选择出入时间
            this.newMonthDateList = [];
            this.pushArr = [
                {
                    time: "",
                    isShowTip: false,
                },
            ];
            this.ruleForm = {
                id: "",
                schoolId: this.schoolId,
                groupName: "",
                accessType: "1",
                accessRule: "1",
                time: "",
                ruleInfo: [],
                organInfo: [],
                showName: "",
                userPopulation: "1",
                scope: "3",
            };

            this.showAddEdit = false;
            this.$emit("changeShowTab", true);
            this.selectObjData = [];
            this.ruleForm.organInfo = [];
            this.isPeopleEdit = false;
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // 选择的出入时间段
                    let weekAccessTimeArr = [];
                    console.log("this.pushArr", this.pushArr);
                    let repeat = this.pushArr.find((i) => i.isShowTip);
                    console.log("repeat", repeat);
                    if (repeat) {
                        return this.$message.warning(
                            "出入时间重复，请仔细检查",
                        );
                    }
                    let nullTimeData = this.pushArr.find((i) => ["", null].includes(i.time));
                    if (nullTimeData) {
                        return this.$message.warning(
                            "出入时间缺少时间段,请仔细检查",
                        );

                    }
                    weekAccessTimeArr = this.pushArr.map((ivs, ivIndexs) => {
                        return ivs.time;
                    });
                    let chooseAccessTimeArr =
                        this._.flattenDeep(weekAccessTimeArr);
                    if (chooseAccessTimeArr && chooseAccessTimeArr.length < 2) {
                        chooseAccessTimeArr = null;
                    }

                    // 每周
                    if (this.ruleForm.accessRule === "1") {
                        console.log("this.weekDateList", this.weekDateList);

                        let filter = this.weekDateList.filter((i) => i.sel);
                        if (filter.length === 0) {
                            return this.$message.warning("请选择出入星期");
                        }
                        // 星期提交的时间段参数
                        let weekArrInfo = [];
                        // 是否可以提交
                        let canSubmit = true;
                        for (let j = 0; j < filter.length; j++) {
                            let weekObjDate = {};
                            weekObjDate.date = filter[j].prop;
                            if (filter[j].value && filter[j].value.length > 0) {
                                let arr = filter[j].value;
                                for (let i = 0; i < arr.length; i++) {
                                    if (["", null].includes(arr[i].time)) {
                                        canSubmit = false;
                                        this.$message.warning(
                                            "出入星期缺少时间,请仔细检查",
                                        );
                                        console.log('出入星期没有时间');
                                        return
                                    }
                                    if (arr[i].isShowWeekTip) {
                                        canSubmit = false;
                                        this.$message.warning(
                                            "出入时间重复,请仔细检查",
                                        );
                                        console.log('出入时间重复');
                                        return
                                    }

                                }
                                filter[j].value.map((item2, index2) => {
                                if (index2 == 0) {
                                    weekObjDate[`startTime`] =
                                        item2.time[0];
                                    weekObjDate[`endTime`] = item2.time[1];

                                } else {
                                    weekObjDate[`startTime${index2}`] =
                                        item2.time[0];
                                    weekObjDate[`endTime${index2}`] = item2.time[1];
                                }
                                });
                            }
                            weekArrInfo.push(weekObjDate);
                        }
                        if (canSubmit) {
                            this.ruleForm.ruleInfo = weekArrInfo;
                        } else {
                            return;
                        }
                    } else {
                        console.log(
                            "this.newMonthDateList提交的",
                            this.newMonthDateList,
                        );
                        // 自定义
                        if (this.newMonthDateList.length === 0) {
                            return this.$message.warning("请选择出入日期");
                        }
                        this.ruleForm.ruleInfo = [];
                        let flag = true;
                        // 星期提交的时间段参数
                        let monthArrInfo = [];
                        // 是否可以提交
                        let canSubmit = true;
                        this.newMonthDateList.map((item1, index1) => {
                            let monthObjDate = {};
                            monthObjDate.date = item1.date;
                            item1.timeArr.map((item2, index2) => {
                                if (
                                    ["", null].includes(item2.time) ||
                                    item2.time.length == 0
                                ) {
                                    canSubmit = false;
                                    return this.$message.warning(
                                        "缺少出入时间,请仔细检查",
                                    );
                                }
                                if (item2.isRepeatDate) {
                                    canSubmit = false;
                                    return this.$message.warning(
                                        "出入时间重复,请仔细检查",
                                    );
                                }
                                if (index2 == 0) {
                                    monthObjDate[`startTime`] =
                                        item2.time[0];
                                    monthObjDate[`endTime`] =
                                        item2.time[1];

                                } else {
                                    monthObjDate[`startTime${index2}`] =
                                        item2.time[0];
                                    monthObjDate[`endTime${index2}`] =
                                        item2.time[1];
                                }
                            });
                            monthArrInfo.push(monthObjDate);
                        });
                        console.log("monthArrInfo", monthArrInfo);
                        console.log("可以提交吗", canSubmit);
                        if (canSubmit) {
                            this.ruleForm.ruleInfo = monthArrInfo;
                        } else {
                            return;
                        }
                    }
                    let campusSafety = new CampusSafetyModel();
                    let data = {
                        accessRule: this.ruleForm.accessRule,
                        accessType: this.ruleForm.accessType,
                        groupName: this.ruleForm.groupName,
                        id: this.ruleForm.id,
                        ruleInfo: this.ruleForm.ruleInfo,
                        schoolId: this.ruleForm.schoolId,
                        userPopulation: this.ruleForm.userPopulation,
                        organList: this.ruleForm.organInfo,
                        accessTimeInfo: chooseAccessTimeArr, //出入时间，bug修改要求编辑时回显出入时间
                        scope: this.ruleForm.scope, // 学生类型
                        stuPick: this.ruleForm.stuPick
                    };
                    console.log("data提交的参数", data);
                    let personData = {
                        accessRule: this.ruleForm.accessRule,
                        accessType: this.ruleForm.accessType,
                        groupName: this.peopleEditObj.personName,
                        id: this.currentPeopleGroupId,
                        ruleInfo: this.ruleForm.ruleInfo,
                        schoolId: this.ruleForm.schoolId,
                        userPopulation: this.ruleForm.userPopulation,
                        organList: this.ruleForm.organInfo,
                        accessTimeInfo: chooseAccessTimeArr, //出入时间，bug修改要求编辑时回显出入时间
                        scope: this.ruleForm.scope, // 学生类型
                    };
                    if (this.isPeopleEdit) {
                        campusSafety.saveAccessGroupPerson(personData).then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success("操作成功");
                                this.resetForm();
                                this.getList();
                                this.getPeopleList(); // 获取列表数据
                                this.getPeopleDoNotPrompt(); // 获取人员编辑是否不再提示
                                this.$eventDispatch("setGlobalPageType", "list");
                                this.isPeopleEdit = false;
                            });
                        });
                    } else {

                        campusSafety.saveAccessGroup(data).then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success("操作成功");
                                this.resetForm();
                                this.getList();
                                this.getPersonData();
                                this.$eventDispatch("setGlobalPageType", "list");
                            });
                        });
                    }
                }
            });
        },
        /**
         * @Description: 组织机构请求下级
         * @DoWhat: 下级查询
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-06-06 14:49:37
         * @param {*} data
         */
        async getNextLevelTreeList(data) {
            const {id, name} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            await this.getTreeData();
            this.$eventBroadcast("getNewTreeList");
        },
        /**
         * @Description: 返回组织机构结果
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-06-06 14:54:27
         * @param {*} data
         */
        determine(data) {
            this.ruleForm.showName = data.map((i) => i.name).join(",");
            this.setCreatedTreeData(data);
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
            for (let item of data) {
                item.scope = this.ruleForm.scope;
            }
            this.selectObjData = data;
            this.dialogObj.dialogVisible = false;
            console.log(data, "selectObjData");
            this.getPeopleSelectedNum();
            this.$refs.ruleForm.clearValidate(["showName"]);
        },
        /**
         * @Description: 初始化树形数据给【谁可以发起】字段
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-04-21 11:04:31
         */
        setCreatedTreeData(selTreeList) {
            let arr = [],
                arr1 = [];
            var ar1 = this.getAllTreeDataItems(selTreeList, arr);
            var treeName = this.getAllFirestTreeDataNames(selTreeList, arr1);
            let firestCreatedData = {
                data: ar1,
                id: "",
                index: "",
                key: "showName",
                selList: treeName,
                typeId: "",
            };
            this.information(firestCreatedData);
        },
        getAllTreeDataItems(data, arr) {
            data.forEach((item) => {
                arr.push(item);
                if (item.children) {
                    this.getAllTreeDataItems(item.children, arr);
                }
            });
            return arr;
        },
        getAllFirestTreeDataNames(data, arr) {
            data.forEach((item) => {
                arr.push(item.name);
            });
            return arr;
        },
        // 获取选中人数
        async getPeopleSelectedNum() {
            const campusOAModel = new CampusOAModel();
            let res = await campusOAModel.getUserByOrgan(this.selectObjData);
            if (res.data.code != "200") {
                this.peopleSelectedNum = 0;
                if (res.data.msg) this.$message.error(res.data.msg);
                return;
            }
            this.peopleSelectedNum = Array.isArray(res.data.data)
                ? res.data.data.length
                : 0;
        },
        // 基础信息填写/谁可以发起
        information(data) {
            console.log("发起的data", data);
            this.ruleForm.organInfo = data.data;
        },

        whoCanInitiate(userPopulation) {
            switch (userPopulation) {
                case "1":
                    this.treeData.type = this.facultyStaff.type;
                    this.treeData.organType = this.facultyStaff.organType;
                    break;
                case "2":
                    this.treeData.type = this.studentParents.type;
                    this.treeData.organType = this.studentParents.organType;
                    break;
                default:
                    break;
            }
        },
        importPeople() {
            this.importDialog.dialogVisible = true;
        },
        // 下载导入模板
        downloadTemplate() {
            this._fet("/accessSchool/schoolAccessGroup/template").then(
                (res) => {
                    if (res.data.code === "200") {
                        downloadFile(
                            {
                                url: res.data.data,
                                method: "get",
                            },
                            () => {
                                this.$message.success("下载模板成功");
                            },
                            () => {},
                        );
                    }
                },
            );
        },
        // 处理上传文件变化
        handleImportChange(file) {
            // 上传文件前的验证
            const beforeUpload = (file) => {
                // 上传文件前的验证
                const fileType = file.name.substring(
                    file.name.lastIndexOf("."),
                );
                const isXLSXorXLS = [".xlsx", ".xls"].includes(fileType);
                const isShortName = file.name.length < 30;
                const isSizeUnder10M = file.size / 1024 / 1024 < 10;

                if (!isXLSXorXLS) {
                    this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                    return false;
                }

                if (!isShortName) {
                    this.$message.error("上传文件名称不能超过30个字符!");
                    return false;
                }

                if (!isSizeUnder10M) {
                    this.$message.error(`上传文件的大小不能超过10M!`);
                    return false;
                }
            };
            const flag = beforeUpload(file);
            if (flag !== false) {
                this.fileList = [file];
            } else {
                this.fileList = [];
            }
        },
        // 处理上传文件移除
        handleImportRemove() {
            this.fileList = [];
        },
        // 提交导入
        handleSubmitImport() {
            if (this.fileList.length === 0) {
                return this.$message.warning("请上传文件");
            }
            this.importLoading = true;
            let formData = new FormData();
            formData.append("file", this.fileList[0].raw);
            formData.append("schoolId", this.schoolId);
            formData.append("type", this.ruleForm.userPopulation);
            if (this.ruleForm.userPopulation == "2") {
                formData.append("studentType", this.ruleForm.scope);
            }
            let campusSafety = new CampusSafetyModel();
            campusSafety
                .uploadImportPerson(formData)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.$message.success("人员导入成功");
                        this.handleImportClose();
                        this.getShowPeopleName(res.data.data);
                    } else if (res.data.code === "20013") {
                        this.downloadErrorList(res.data);
                    } else {
                        this.$message.error("人员导入失败");
                    }
                })
                .finally(() => {
                    this.importLoading = false;
                });
        },
        getShowPeopleName(data) {
            this.ruleForm.showName = data.map((i) => i.name).join(",");
            this.setCreatedTreeData(data);
            this.treeData.parentId = "";
            this.treeData.name = "";
            for (let item of data) {
                item.scope = this.ruleForm.scope;
            }
            this.selectObjData = data;
            console.log("selectObjData导入回显的人员信息", data);
            this.getPeopleSelectedNum();
        },
        // 下载导入错误数据
        downloadErrorList(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/accessSchool/schoolAccessGroup/errorExport",
                            method: "post",
                            form: {
                                paramData: data.info.paramData,
                            },
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        // 关闭导入弹窗
        handleImportClose() {
            this.fileList = [];
            this.importDialog.dialogVisible = false;
        },
        /**
         * @Description: 查找数组中有重复项（按月）
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-24 14:49:37
         */
        searchArrRepeat(curObj) {
            let arr = curObj.repeatData.timeRangeArr;
            if (arr) {
                let bbb = arr.map((i) => {
                    return i.isRepeatDate;
                });
                return bbb.some((x) => x == true);
            }
            return false;
        },
        /**
         * @Description: 查找数组中有重复项（按周）
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-06-28 10:26:45
         */
        searchWeekArrRepeat(curObj) {
            let arr = curObj.value;
            if (arr) {
                let bbb = arr.map((i) => {
                    return i.isShowWeekTip;
                });
                return bbb.some((x) => x == true);
            }
            return false;
        },
        handleStuPickChange(val) {
            console.log('handleStuPickChange-val',val);
            this.$set(this.ruleForm, "stuPick", val);
            console.log(this.ruleForm);

            
        }
    },
};
</script>

<style scoped lang="scss">
.common-form-view /deep/ .el-loading-mask {
    top: 5px !important;
    // left: 5px !important;
}
.filter-wrap-all {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 0px 4px 4px 4px;
    overflow: hidden;
}
.filter-wrap {
    display: flex;
    justify-content: space-between;
    .expand-filter {
        padding: 0;
    }
}

.common-form-wrapper {
    white-space: normal;
    margin-top: 0px;
    padding-top: 10px;
}

::v-deep .common-form-main {
    height: calc(100vh - 198px);
}

.el-dropdown {
    margin-left: 10px;
}

.el-dropdown-menu {
    width: 111px;
}

.el-dropdown-menu__item {
    padding: 0 10px;
    text-align: center;
    color: #333333;
    font-size: 14px;

    &:hover {
        background-color: #fff;
        color: #3c7fff;
    }
}

.add-edit {
    .content {
        position: relative;
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;
        overflow: auto;

        .focusPeople {
            width: 750px;
            overflow: hidden;
            height: 32px;
            line-height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #dcdee0;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;

            .all_people {
                width: 656px;
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                align-items: center;
                // justify-content: start;
            }

            .each_people {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                padding: 0 4px;
                height: 24px;
                width: 90px;
                overflow: hidden;
                /* 隐藏超出部分的内容 */
                white-space: nowrap;
                /* 禁止文本换行 */
                text-overflow: ellipsis;
                /* 在文本末尾添加省略号 */

                text-align: center;
                line-height: 24px;
                margin: auto 2px;
                background: #f0f0f0;
                border-radius: 2px;
                font-size: 12px;
                color: #6d6f73;
                box-sizing: border-box;
            }

            .elipls {
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 0 4px;
                width: 26px;
                height: 24px;
                text-align: center;
                // line-height: 26px;
                background: #f0f0f0;
                border-radius: 2px;
                margin: auto 2px;
            }

            .people_num {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                padding: 0 4px;
                margin: 0 2px;
                flex-shrink: 0;
                background: #f0f0f0;
                border-radius: 2px;
                padding: 0 12px;
            }
        }

        .import_btn {
            width: 65px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #e5f0ff;
            border-radius: 4px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #3c7fff;
            margin-top: 8px;
            cursor: pointer;
        }

        .popper__arrow {
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #fff;
            bottom: -24px;
            z-index: 99999;
            transform: rotate(45deg);
            border-left: 1px solid #e4e7ed;
            border-top: 1px solid #e4e7ed;
        }

        /deep/ .el-card__body {
            padding: 10px !important;
            padding-bottom: 130px !important;
        }

        ::v-deep {
            .el-card {
                border-radius: 8px;
            }
        }

        .week-wrap {
            display: flex;
            overflow-x: auto;

            .first-week-content {
                padding: 10px 5px;
                border-radius: 8px;
                box-sizing: border-box;
                min-width: 114px;

                .el-date-editor {
                    padding: 3px 0;
                    background-color: #fafcff;
                    border: 0;
                    border-radius: 2px;

                    /deep/ .el-range-input {
                        background-color: #fafcff;
                    }

                    /deep/ .el-range-separator {
                        line-height: 26px;
                        padding: 0;
                    }
                }

                .label {
                    color: #2b2e33;
                    font-size: 14px;
                }
            }

            .week-content {
                margin-left: 10px;
                padding: 10px 5px;
                border-radius: 8px;
                box-sizing: border-box;
                min-width: 114px;

                .el-date-editor {
                    padding: 3px 0;
                    background-color: #fafcff;
                    border: 0;
                    border-radius: 2px;

                    /deep/ .el-range-input {
                        background-color: #fafcff;
                    }

                    /deep/ .el-range-separator {
                        line-height: 26px;
                        padding: 0;
                    }
                }

                .label {
                    color: #2b2e33;
                    font-size: 14px;
                }
            }
        }
    }
    .calendar {
        .el-date-editor {
            width: 100%;
            padding: 3px 0;
            background-color: #fafcff;
            border: 0;
            border-radius: 2px;

            /deep/ .el-range__icon {
                display: none;
            }

            /deep/ .el-range__close-icon {
                width: 10px;
            }

            /deep/ .el-range-input {
                background-color: #fafcff;
            }

            /deep/ .el-range-separator {
                line-height: 26px;
                padding: 0;
            }
        }
    }

    .header {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2e33;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 12px;
    }

    .time_group_add {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .add_time_tip {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #919599;
            margin-left: 8px;
        }

        .el-icon-circle-plus {
            font-size: 22px;
            color: #3c7fff;
        }
    }

    .el-icon-remove {
        font-size: 22px;
        color: #c8cacc;
        margin-top: 4px;
    }

    .el-icon-remove:hover {
        font-size: 22px;
        color: #f24949;
        margin-top: 4px;
    }

    .push_each {
        display: flex;
        justify-content: space-between;
        position: relative;

        .each_setting {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            background: #ffffff;
            border-radius: 4px;
            font-family: Microsoft YaHei;
            font-size: 14px;
            font-weight: 400;
            color: #2b2f33;
            margin-bottom: 12px;
        }

        .repeat_tip {
            position: absolute;
            color: #d93636;
            font-size: 12px;
            bottom: -9px;
            left: 10px;
        }
    }

    .week_repeat_tip {
        color: #d93636;
        font-size: 12px;
    }

    .show_time_box {
        position: absolute;
        top: 34px;
        right: -11px;
        z-index: 2;
        padding: 0 10px 10px;
        background: #e5f0ff;
        box-shadow: 0px 6px 12px 0px #dadfe6;
        border: 1px dashed #3c7fff;
        border-top: none;
        width: 121px;
        box-sizing: border-box;
        &.show_time_box1 {
            width: 121px;
        }
    }
}
.is_repeat {
    ::v-deep .el-range-input {
        color: #d93636;
    }
}
.repeat_tip_only {
    color: #d93636;
    font-size: 12px;
    position: absolute;
    top: 2px;
    left: 2px;
}
.repeat_tip_only_week {
    color: #d93636;
    font-size: 12px;
}
.teacher-student {
    display: flex;
    justify-content: flex-end;
    div {
        width: 30px;
        height: 30px;
        border: 1px solid #C7C7C7;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    div:hover {
        cursor: pointer;
    }
    .teacher {
        border-radius: 4px 0px 0px 4px;
        img {
            width: 28px;
            height: 20px;
        }
    }
    .student {
        border-radius: 0px 4px 4px 0px;
        img {
            width: 20px;
            height: 21px;
        }
    }
    .active-style {
        border: 1px solid transparent;
        background: #3C7FFF;
    }
}
.set_btn {
    height: 32px;
    background-color: #0EA5B3;
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    width: 64px;
    font-size: 14px;
}
.set-dialog-box {
    ::v-deep .el-dialog .el-dialog__body {
        padding: 24px 30px 28px 30px !important;
    }
    // ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    //     color: #2B2F33;
    // }
    .set-wrap {
        
        padding: 0 18px 18px;
        .teacher-type,.student-type {
            display: flex;
            align-items: center;
        }
        .teacher-type {
            margin-bottom: 20px;
        }
        .teacher_title {
            font-size: 14px;
            color: #2B2F33;
            margin-right: 28px;
        }
    }
    .set_tip_wrap {
        margin-top: 20px;
        background: #F7F7F7;
        border-radius: 4px;
        padding: 18px;
        .each_tip {
            line-height: 24px;
            color: #919599;
            font-size: 13px;
        }
    }
}
.detail-dialog-box {
    ::v-deep .el-dialog .el-dialog__body {
        padding: 12px 28px 28px !important;
    }
    /deep/ .el-table th.el-table__cell {
        color: #363b40 !important;
        font-size: 14px;
        font-weight: 400;
        background-color: #F7F7F7 !important;
        border: 1px solid #E1E3E6 !important;
        border-top: none !important;
        &:nth-child(n) {
            border-left: none !important;
        }
    }

    /deep/ .el-table__body td.el-table__cell {
        border: 1px solid #E1E3E6 !important;
        font-size: 14px;
        color: #363b40;

        border-top: none !important;
        &:nth-child(n) {
            border-left: none !important;
        }
    }
}
.del-dialog-box {
    .tip_title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 18px;
        color: #2B2F33;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .tip_content {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #6D6F73;
        line-height: 24px;
    }
    .tip_content1 {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        color: #6D6F73;
        line-height: 24px;
    }
    ::v-deep .el-checkbox__label {
        font-size: 13px;
        color: #9DA1A6;
    }
}
.people_tip {
    font-size: 12px;
    color: #F56C6C;
    margin-top: 3px;
    line-height: 14px;
}
</style>
